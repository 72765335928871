import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Dropdown, Row, Col } from "react-bootstrap";
// import Sort from "../../assets/image/sort.svg";
import Filter from "../../assets/image/filterr.svg";
import Sort from "../../assets/image/sort-down-alt.svg";

import Dummy from "../../assets/image/demo-sm.svg";
import Del from "../../assets/image/rounded-remove.svg";
import Edt from "../../assets/image/edit.svg";
import Pagination from "../../Components/Pagination/Pagination";
import { useDispatch } from "react-redux";
import { showToast } from "../../Services/tostify";
import Spinnerr from "../../Components/Spinner/Spinner";
import { useDeleteCouponMutation, useGetCouponListMutation } from "../../Services/api";
import moment from "moment";
import DiscountModal from "./DiscountModal";

const DiscountCouponManagement = ({ isNotification }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const [show, setShow] = useState(false);
    const [couponId, setCouponId] = useState(null);
    const [couponData, setCouponData] = useState(null);

    const [page, setPage] = useState(1); // Initialize page state to start from 0
    const [totalPages, setTotalPages] = useState(1);
    const [nextPage, setNextPage] = useState(null);
    const [searchQuery, setSearchQuery] = useState({
        page: 1,
        limit: 10,
        search: "",
        sort: {
            //   coupon_code: -1, // send 1:=> for assending, send -1:=> for desending
            createdAt: -1, // send 1:=> for oldest to newest, send -1 :=> for newest to oldest
        },
    });
    const [couponList, response] = useGetCouponListMutation();
    const [deleteCoupon, response2] = useDeleteCouponMutation();

    useEffect(() => {
        if (!response.isLoading && response.isSuccess) {
            setLoading(false);
            setData(response?.data.data);
            setPage(response?.data?.data?.page);
            setTotalPages(response?.data?.data?.total_pages);
            setNextPage(response?.data?.data?.next_page);
            if (response.data?.data?.notification_unread_count > 0) {

                isNotification(response.data?.data?.notification_unread_count);

            }
        } else if (response.isError && response.status === "rejected") {
            setError(true);
            setLoading(false);
        }
    }, [response]);

    useEffect(() => {
        couponList(searchQuery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    const handleSearch = (e) => {
        setSearchQuery({ ...searchQuery, search: e.target.value, page: 1 });
    };

    const handleClose = async () => {
        setShow(false);
        setCouponData(null);
        setCouponId(null);
        await couponList(searchQuery);
    };

    const [delet, setDelet] = useState(false);
    const deleteClose = () => setDelet(false);
    const deleteShow = () => setDelet(true);

    const handleDeleteConfirmation = async () => {
        await deleteCoupon(couponId);
    };

    useEffect(() => {
        if (!response2.isLoading && response2.isSuccess) {
            setLoading(false);
            deleteClose();
            couponList(searchQuery);
        } else if (response2.isError && response2.status === "rejected") {
            setError(true);
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response2]);

    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "some error occured"
            ) : (
                <div className="dash-first dash-common">
                    <div className="common-table-wrapper m-0">
                        <Row className="mb-4">
                            <Col md={6}>
                                <div className="flex gap-3">
                                    <label className="relative block">
                                        <span className="sr-only">Search</span>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                            <svg className="h-5 w-5 fill-slate-500" viewBox="0 0 20 20">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                        </span>
                                        <input
                                            className="placeholder:text-gray-400 block bg-gray-200 w-full border border-slate-300 rounded-xl py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base"
                                            placeholder="Search"
                                            type="text"
                                            name="Search"
                                            value={searchQuery.search}
                                            onChange={handleSearch}
                                        />
                                    </label>
                                    <div className="flex items-center gap-3">
                                        <Dropdown>
                                            <Dropdown.Toggle className="bg-transparent p-0 border-0" id="dropdown-basic">
                                                <span>
                                                    <img src={Sort} alt="sort" />
                                                </span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="shadow border-0 rounded-xl">
                                                <Dropdown.Item onClick={() => setSearchQuery({ ...searchQuery, sort: { coupon_code: 1 }, page: 1 })}>
                                                    Sort by Coupon Code (ASC)
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => setSearchQuery({ ...searchQuery, sort: { coupon_code: -1 }, page: 1 })}>
                                                    Sort by Coupon Code (DESC)
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => setSearchQuery({ ...searchQuery, sort: { createdAt: 1 }, page: 1 })}>
                                                    Sort by Date (Oldest first)
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => setSearchQuery({ ...searchQuery, sort: { createdAt: -1 }, page: 1 })}>
                                                    Sort by Date (Newest first)
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} className="text-end">
                                <Link
                                    className="text-sky-600 hover:text-sky-800  outline-1 outline-double rounded-full text-sm font-semibold px-4 py-2 d-inline-block"
                                    onClick={() => {
                                        setShow(true);
                                    }}
                                >
                                    + Add more
                                </Link>
                            </Col>
                        </Row>
                        <div className="table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Sr.No.</th>
                                        <th>Coupon Name</th>
                                        <th>Discounted percentage</th>
                                        <th>Discount Upto</th>
                                        <th>Valid From</th>
                                        <th>Valid To</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.coupon_list.length > 0 ? (
                                        data.coupon_list.map((coupon, i) => (
                                            <tr key={i}>
                                                <td>{(page - 1) * 10 + i + 1}</td>
                                                <td>
                                                    <span className="flex gap-2 items-center">
                                                        <img
                                                            src={coupon.coupon_image || Dummy}
                                                            width="15"
                                                            height="15"
                                                            className="rounded-full"
                                                            alt={coupon._id}
                                                        />
                                                        {coupon.coupon_code}
                                                    </span>
                                                </td>
                                                <td>{coupon.percentage}%</td>
                                                <td>{coupon?.upto || 0}</td>
                                                <td>{moment(coupon.valid_from * 1000).format("DD-MMM-YY")}</td>
                                                <td>{moment(coupon.valid_to * 1000).format("DD-MMM-YY")}</td>

                                                <td className="action-tab flex justify-center gap-2">
                                                    <Link
                                                        className="inline-block"
                                                        onClick={() => {
                                                            setCouponId(coupon._id);
                                                            setCouponData(coupon);
                                                            setShow(true);
                                                        }}
                                                    >
                                                        <img src={Edt} alt="" />
                                                    </Link>
                                                    <Link
                                                        className="inline-block"
                                                        onClick={() => {
                                                            deleteShow();
                                                            setCouponId(coupon._id);
                                                        }}
                                                    >
                                                        <img src={Del} alt="" />
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <td className="text-center" colSpan={13}>
                                            No Records Founds
                                        </td>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {data?.coupon_list.length > 0 ? (
                            <Row className="mt-4 px-2">
                                <Col md={5}>
                                    <div className="dataTables_info">
                                        {`Showing ${Math.min((page - 1) * 10 + 1, data?.total)}` +
                                            ` to ${Math.min(page * 10, data?.total) || 0} of ${data?.total || 0} entries`}
                                    </div>
                                </Col>
                                <Col md={7}>
                                    <Pagination
                                        total={totalPages}
                                        setPage={(newPage) => {
                                            setSearchQuery((prevQuery) => ({
                                                ...prevQuery,
                                                page: newPage,
                                            }));
                                        }}
                                        page={page} // Add 1 to page state to show correct page number
                                        nextPage={nextPage} // Pass the next page number to the Pagination component
                                    />
                                </Col>
                            </Row>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            )}
            <DiscountModal show={show} handleClose={handleClose} id={couponId} couponData={couponData} />
            <Modal dialogClassName="modal-custom-sm" centered show={delet} onHide={deleteClose}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3">
                            <p className="modalTitle text-center mb-2 fw-bold">
                                Are you sure you want to
                                <br />
                                delete this Coupon?
                            </p>
                            <form className="custom_form">
                                <div className="btn-wrapper text-center">
                                    <Link className="btn btn-form btn-cancel" onClick={deleteClose}>
                                        No
                                    </Link>
                                    <Link
                                        className="btn btn-form btn-add"
                                        onClick={() => {
                                            handleDeleteConfirmation();
                                        }}
                                    >
                                        Yes
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DiscountCouponManagement;
