import React, { Suspense, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import EventDetail from "./Pages/UserManagement/EventDetail";
import Leaderboard from "./Pages/UserManagement/Leaderboard";
import EventRequests from "./Pages/EventRequests/EventRequests";
import EventDetails from "./Pages/EventRequests/EventDetail";
import OrganizerManagement from "./Pages/OrganizerManagement/OrganizerManagement";
import ViewOrganizer from "./Pages/OrganizerManagement/ViewOrganizer";
import OrganizerEventDetail from "./Pages/OrganizerManagement/OrganizerEventDetail";
import Review from "./Pages/OrganizerManagement/Review";
import ViewRequest from "./Pages/OrganizerManagement/ViewRequest";
import PaymentManagement from "./Pages/PaymentManagement/PaymentManagement";
import PaymentDetail from "./Pages/PaymentManagement/PaymentDetail";
import DiscountCouponManagement from "./Pages/DiscountCouponManagement/DiscountCouponManagement";
import CMS from "./Pages/CMS/CMS";
import SupportHelpdesk from "./Pages/SupportHelpdesk/SupportHelpdesk";
import ViewRequestSupport from "./Pages/SupportHelpdesk/ViewRequestSupport";
import FeedbackSuggestion from "./Pages/FeedbackSuggestion/FeedbackSuggestion";
import ViewRequestFeedback from "./Pages/FeedbackSuggestion/ViewRequestFeedback";
import { ToastifyContainer } from "./Services/tostify";
import EventManagement from "./Pages/EventManagement/EventManagement";
import Penality from "./Pages/Penality/Penality";
import OtpSend from "./Pages/Login/Otp-send";
import ResetPassword from "./Pages/Login/ResetPassword";
import SendPushNotification from "./Pages/SendPushNotification/SendPushNotification";
import News from "./Pages/News/News";
import AddNews from "./Pages/News/AddNews";

const NotificationManagement = React.lazy(() => import("./Pages/NotificationManagement/NotificationManagement"));

const Layout = React.lazy(() => import("./Components/Layout/Layout"));

const Spinnerr = React.lazy(() => import("./Components/Spinner/Spinner"));
const Login = React.lazy(() => import("./Pages/Login/Login"));
const UpdatePassword = React.lazy(() => import("./Pages/Login/UpdatePassword"));
const Dashboard = React.lazy(() => import("./Pages/Dashboard/Dashboard"));
const UserManagement = React.lazy(() => import("./Pages/UserManagement/UserManagement"));

const ViewUser = React.lazy(() => import("./Pages/UserManagement/ViewUser"));

const AuthLayout = React.lazy(() => import("./Components/Layout/AuthLayout"));

function App() {
    const [isNotification, setIsNotification] = useState("");
    console.log(isNotification);
    return (
        <>
            <Suspense fallback={<Spinnerr />}>
                <ToastifyContainer />
                <Routes>
                    <Route path="/" element={<AuthLayout />}>
                        <Route index element={<Login />} />
                        <Route path="/update-password" element={<UpdatePassword />} />
                        <Route path="/verify-otp/:id" element={<OtpSend />} />
                        <Route path="/reset-password/:id" element={<ResetPassword />} />
                    </Route>

                    <Route element={<Layout isNotification={isNotification} />}>
                        <Route index path="/dashboard" element={<Dashboard isNotification={setIsNotification} />} />
                        <Route path="/user-management" element={<UserManagement isNotification={setIsNotification} />} />
                        <Route path="/user-management/view-user/:id" element={<ViewUser isNotification={setIsNotification} />} />
                        <Route path="/user-management/view-user/:id/event-detail/:id" element={<EventDetail isNotification={setIsNotification} />} />
                        {/* <Route path="/user-management/view-user/:id/event-detail/:id/leaderboard" element={<Leaderboard isNotification={setIsNotification} />} /> */}
                        <Route path="/leaderboard" element={<Leaderboard isNotification={setIsNotification} />} />

                        <Route path="/event-management" element={<EventManagement isNotification={setIsNotification} />} />
                        <Route index path="/event-management/event-details/:id" element={<EventDetails isNotification={setIsNotification} />} />
                        <Route index path="/event-requests" element={<EventRequests isNotification={setIsNotification} />} />

                        <Route path="/organizer-management" element={<OrganizerManagement isNotification={setIsNotification} />} />
                        <Route path="/organizer-management/view-request/:id" element={<ViewRequest isNotification={setIsNotification} />} />
                        <Route path="/organizer-management/view-organizer/:id" element={<ViewOrganizer isNotification={setIsNotification} />} />
                        <Route path="/organizer-management/view-organizer/:id/review" element={<Review isNotification={setIsNotification} />} />
                        <Route
                            path="/organizer-management/view-organizer/:id/organizer-event-detail/:id"
                            element={<OrganizerEventDetail isNotification={setIsNotification} />}
                        />

                        <Route path="/payment-management" element={<PaymentManagement isNotification={setIsNotification} />} />
                        <Route path="/payment-management/payment-detail/:id" element={<PaymentDetail isNotification={setIsNotification} />} />

                        <Route path="/discount-coupon-management" element={<DiscountCouponManagement isNotification={setIsNotification} />} />
                        <Route path="/send-push-notification" element={<SendPushNotification isNotification={setIsNotification} />} />
                        <Route path="/news" element={<News isNotification={setIsNotification} />} />
                        <Route path="/news/addnews" element={<AddNews isNotification={setIsNotification} />} />

                        <Route path="/cms" element={<CMS isNotification={setIsNotification} />} />
                        <Route path="/support-helpdesk" element={<SupportHelpdesk isNotification={setIsNotification} />} />
                        <Route
                            path="/support-helpdesk/view-request-support/:id"
                            element={<ViewRequestSupport isNotification={setIsNotification} />}
                        />

                        <Route path="/feedback-suggestion" element={<FeedbackSuggestion isNotification={setIsNotification} />} />
                        <Route
                            path="/feedback-suggestion/view-request-feedback/:id"
                            element={<ViewRequestFeedback isNotification={setIsNotification} />}
                        />

                        <Route path="/notification-management" element={<NotificationManagement isNotification={setIsNotification} />} />

                        <Route path="/penalty" element={<Penality isNotification={setIsNotification} />} />
                    </Route>
                </Routes>
            </Suspense>
        </>
    );
}

export default App;
