import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sort from "../../assets/image/sort-down-alt.svg";
import Dummy from "../../assets/image/user-circle.svg";
import View from "../../assets/image/view.svg";
import Pagination from "../../Components/Pagination/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { useGetFeedbackNSuggestionListMutation } from "../../Services/api";
import Spinnerr from "../../Components/Spinner/Spinner";
import moment from "moment";

const FeedbackSuggestion = ({ isNotification }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);

    const [feedbackList, response] = useGetFeedbackNSuggestionListMutation();

    const [page, setPage] = useState(1); // Initialize page state to start from 0
    const [totalPages, setTotalPages] = useState(1);
    const [nextPage, setNextPage] = useState(null);

    const [searchQuery, setSearchQuery] = useState({
        page: 1,
        limit: 10,
        search: "",
        sort: {
           
        },
    });

    useEffect(() => {
        feedbackList(searchQuery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, page]);

    useEffect(() => {
        if (!response.isLoading) {
            setLoading(false);
            setData(response?.data?.data);
            // Update current page, total pages, and next page from response data
            setPage(response?.data?.data?.page);
            setTotalPages(response?.data?.data?.total_pages);
            if (response.data?.data?.notification_unread_count > 0) {
            
               isNotification(response.data?.data?.notification_unread_count);
            
            }
            // Assuming the API response has a field named `next_page` for the next page number
            setNextPage(response?.data?.data?.next_page);
        } else if (response.isError) {
            setLoading(false);
            setError(true);
        }
    }, [response]);
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchQuery((prevQuery) => ({
            ...prevQuery,
            page: 1, // Reset page to 0 when searching
            search: value,
        }));
    };
    const handleSort = (sort) => {
        setSearchQuery({ ...searchQuery, sort, page: 1 });
    };

    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "some error occured"
            ) : (
                <>
                    <div className="dash-first dash-common">
                        <div className="common-table-wrapper m-0">
                            <Row className="mb-4">
                                <Col md={6}>
                                    <div className="flex gap-3">
                                        <label className="relative block">
                                            <span className="sr-only">Search</span>
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                                <svg className="h-5 w-5 fill-slate-500" viewBox="0 0 20 20">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                            </span>
                                            <input
                                                className="placeholder:text-gray-400 block bg-gray-200 w-full border border-slate-300 rounded-xl py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base"
                                                placeholder="Search"
                                                type="text"
                                                name="Search"
                                                onChange={handleSearchChange}
                                            />
                                        </label>
                                        <div className="flex items-center gap-3">
                                            <Dropdown>
                                                <Dropdown.Toggle className="bg-transparent p-0 border-0" id="dropdown-basic">
                                                    <span className="">
                                                        <img src={Sort} alt="filter" />
                                                    </span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="shadow border-0 rounded-xl">
                                                    <Dropdown.Item onClick={() => handleSort({ first_name: 1 })}>Sort by Name (A-Z)</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleSort({ first_name: -1 })}>Sort by Name (Z-A)</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleSort({ is_reply: 1 })}>Sort by Not Replied </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleSort({ is_reply: -1 })}>Sort by Replied</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}></Col>
                            </Row>
                            <div className="table-responsive">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sr.No.</th>
                                            <th>User name</th>
                                            <th>Phone</th>
                                            <th>Suggestion/Feedback</th>
                                            <th>Date</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.feedback_and_suggestion.length > 0 ? (
                                            data?.feedback_and_suggestion.map(({ _id, title, user_data, description, createdAt }, i) => (
                                                <tr key={_id}>
                                                    <td>{(page - 1) * 10 + i + 1}</td>
                                                    <td>
                                                        <span className="flex gap-2 items-center">
                                                            <img
                                                                src={user_data?.image || Dummy}
                                                                width="15"
                                                                height="15"
                                                                className="rounded-full"
                                                                alt="Dummy"
                                                            />
                                                            {user_data?.first_name || "-"}
                                                        </span>
                                                    </td>
                                                    <td>{user_data?.phone || "-"}</td>
                                                    <td
                                                        style={{
                                                            maxWidth: "200px",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <span>{description}</span>
                                                    </td>
                                                    <td>{moment(createdAt).format("M/DD/YYYY")}</td>
                                                    <td className="action-tab flex justify-center gap-2">
                                                        <Link className="inline-block" to={`/feedback-suggestion/view-request-feedback/${_id}`}>
                                                            <img src={View} alt="" />
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td className="text-center" colSpan={12}>
                                                    No data Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            {data?.feedback_and_suggestion.length > 0 ? (
                                <Row className="mt-4 px-2">
                                    <Col md={5}>
                                        <div className="dataTables_info">
                                            {`Showing ${Math.min((page - 1) * 10 + 1, data?.total)}` +
                                                ` to ${Math.min(page * 10, data?.total) || 0} of ${data?.total || 0} entries`}
                                        </div>
                                    </Col>
                                    <Col md={7}>
                                        <Pagination
                                            total={totalPages}
                                            setPage={(newPage) => {
                                                setSearchQuery((prevQuery) => ({
                                                    ...prevQuery,
                                                    page: newPage,
                                                }));
                                            }}
                                            page={page} // Add 1 to page state to show correct page number
                                            nextPage={nextPage} // Pass the next page number to the Pagination component
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default FeedbackSuggestion;
