// StarRating.js
import React from "react";
import { MdStar, MdStarBorder, MdStarHalf } from "react-icons/md";

const StarRating = ({ value }) => {
  const renderStars = () => {
    const stars = [];
    const filledStars = Math.floor(value);
    const hasHalfStar = value - filledStars >= 0.5;

    for (let i = 0; i < 5; i++) {
      if (i < filledStars) {
        stars.push(<MdStar key={i} className="text-amber-500 text-md" />);
      } else if (i === filledStars && hasHalfStar) {
        stars.push(<MdStarHalf key={i} className="text-amber-500 text-md" />);
      } else {
        stars.push(<MdStarBorder key={i} className="text-amber-500 text-md" />);
      }
    }

    return stars;
  };

  return <>{renderStars()}</>;
};

export default StarRating;
