import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Dummy from "../../assets/image/user-circle.svg";
import Demo from "../../assets/image/demo-image.png";
import Pagination from "../../Components/Pagination/Pagination";
import View from "../../assets/image/view.svg";
import { MdBlock, MdStar } from "react-icons/md";
import { useBlockUserMutation, useGetEventListMutation, useGetSingleOrganiserQuery } from "../../Services/api";
import Spinnerr from "../../Components/Spinner/Spinner";
import moment from "moment";
import { showToast } from "../../Services/tostify";
import { useDispatch } from "react-redux";

const ViewOrganizer = ({ isNotification }) => {
    const location = useLocation();
    const dispatch = useDispatch();

    const id = location.pathname.split("/")[3];
    const [page, setPage] = useState(1); // Initialize page state to start from 0
    const [totalPages, setTotalPages] = useState(1);
    const [nextPage, setNextPage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [data, setData] = useState(null);
    const [status, setStatus] = useState(null);
    const [organiserData, setOrganiserData] = useState(null);
    const [block, setBlock] = useState(false);
    const [searchQuery, setSearchQuery] = useState({
        page: 1,
        limit: 10,
        search: "",
        sort: {
            createdAt: -1,
        },
        organizer_id: id,
    });

    const singleOrganizer = useGetSingleOrganiserQuery(id, { refetchOnMountOrArgChange: true });
    const [eventList, response] = useGetEventListMutation();
    const [userBlock, response2] = useBlockUserMutation();

    useEffect(() => {
        eventList(searchQuery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    useEffect(() => {
        if (!response.isLoading && response.isSuccess && !singleOrganizer.isLoading) {
            setLoading(false);
            setData(response?.data?.data);
            setOrganiserData(singleOrganizer?.data?.data);
            setPage(response?.data?.data?.page);
            setTotalPages(response?.data?.data?.total_pages);
            setNextPage(response?.data?.data?.next_page);
            if (response.data?.data?.notification_unread_count > 0) {
            
               isNotification(response.data?.data?.notification_unread_count);
            
            }
        } else if (response.isError && response.status === "rejected" && !singleOrganizer.isError) {
            setError(true);
            setLoading(false);
        }
    }, [response, singleOrganizer]);
    const blockClose = () => setBlock(false);
    const blockShow = () => setBlock(true);

    const handleBlockUser = async (e) => {
        e.preventDefault();
        await userBlock({ user_id: id, status: status });
        // Close the modal after making the API call
    };

    useEffect(() => {
        if (!response2.isLoading && response2.isSuccess) {
            blockClose();
            setLoading(false); // Assuming the page should stop loading after the block API call
            // userData(searchQuery);
            singleOrganizer.refetch();
            dispatch(showToast(`Organiser ${status !== 2 ? "unblocked" : "blocked"}`, "SUCCESS_TOAST"));
        } else if (response2.isError && response2.status === "rejected") {
            // Handle error if the block API call fails
            setLoading(false);
            setError(true);
            dispatch(showToast("Some error occured", "FAIL_TOAST"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response2]);
    return (
        <>
            {response.isLoading && loading && <Spinnerr />}
            {response.error && error && <div>Error: {response.error.message}</div>}
            {!response.isLoading && !response.error && data && (
                <>
                    <Row>
                        <Col xl={2}>
                            <Link
                                className="bg-gray-800 hover:bg-gray-600 text-white outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 d-inline-block"
                                to={"/organizer-management"}
                            >
                                Back
                            </Link>
                        </Col>
                        <Col xl={8}>
                            <div className="text-center">
                                <div className="inline-flex flex-col justify-center items-center">
                                    <img
                                        src={organiserData?.organizer_detail?.image || Dummy}
                                        alt="User"
                                        className="rounded-full"
                                        width="48"
                                        height="48"
                                    />
                                    <div className="inline-flex items-center mt-2 justify-center gap-1">
                                        <span className="text-2xl font-bold mb-0">{organiserData?.organizer_detail?.organizer_name}</span>
                                        <Link
                                            to={`/organizer-management/view-organizer/${id}/review`}
                                            className="text-xs inline-flex items-center gap-1 bg-amber-100 hover:text-gray-800 rounded-md py-1 px-2"
                                        >
                                            <MdStar className="text-amber-600 text-md" /> {organiserData?.organizer_detail?.reviews.toFixed(2)}
                                        </Link>
                                    </div>
                                    <span className="text-base opacity-50 font-semibold">{organiserData?.organizer_detail?.business_email}</span>
                                </div>
                            </div>
                        </Col>
                        <Col xl={2} className="text-end">
                            <Link
                                className="text-stone-600 hover:text-stone-800 outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 inline-flex items-center justify-center gap-1"
                                onClick={() => {
                                    blockShow();
                                    setStatus(organiserData?.organizer_detail?.status !== 8 ? 2 : 1);
                                }}
                            >
                                <MdBlock /> {organiserData?.organizer_detail?.status !== 8 ? "block" : "Unblock"}
                            </Link>
                        </Col>
                    </Row>
                    <Row className="mt-5 col-10 mx-auto justify-content-center">
                        {organiserData?.organizer_detail?.business_images.map(({ image, _id }, i) => (
                            <Col xl={2} key={i}>
                                <div className="h-32 rounded-xl overflow-hidden relative">
                                    <img className="d-block w-100 h-100 object-cover" src={image} alt={_id} />
                                </div>
                            </Col>
                        ))}
                    </Row>

                    <div className="common-table-wrapper m-0 mt-5">
                        <Row className="mb-1">
                            <Col md={12}>
                                <h6 className="text-base font-semibold mb-0">Events History</h6>
                            </Col>
                        </Row>
                        <div className="table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Sr.No.</th>
                                        <th>Event Name</th>
                                        <th>Organizer</th>
                                        <th>Location</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>
                                            Event
                                            <br />
                                            Amount
                                        </th>
                                        <th>
                                            Total Revenue
                                            <br />
                                            Generated From It
                                        </th>
                                        <th>My Cut</th>
                                        <th>Tax</th>
                                        <th>
                                            Amount To
                                            <br />
                                            Distribute
                                        </th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.event_list.length > 0 ? (
                                        data.event_list.map(
                                            (
                                                {
                                                    _id,
                                                    event_name,
                                                    organizer_detail,
                                                    location,
                                                    createdAt,
                                                    total_revenue,
                                                    my_cut,
                                                    price,
                                                    total_tax,
                                                    amount_to_distribute,
                                                },
                                                i,
                                            ) => (
                                                <tr key={i}>
                                                    <td>{(page - 1) * 10 + i + 1}</td>
                                                    <td>{event_name}</td>
                                                    <td>{organizer_detail?.organizer_name}</td>
                                                    <td>{location}</td>
                                                    <td>{moment(createdAt).format("MM/DD/YYYY")}</td>
                                                    <td>{moment(createdAt).format("HH : mm A")}</td>
                                                    <td>₹ {price}</td>
                                                    <td>₹{total_revenue !== null ? total_revenue.toFixed(2) : 0}</td>
                                                    <td>₹{my_cut !== null ? my_cut.toFixed(2) : 0}</td>
                                                    <td>₹{total_tax !== null ? total_tax.toFixed(2) : 0}</td>
                                                    <td>₹{amount_to_distribute !== null ? amount_to_distribute.toFixed(2) : 0}</td>
                                                    <td>
                                                        {" "}
                                                        <Link
                                                            className="inline-block"
                                                            to={`/organizer-management/view-organizer/${id}/organizer-event-detail/${_id}`}
                                                        >
                                                            <img src={View} alt="" />
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ),
                                        )
                                    ) : (
                                        <td className="text-center" colSpan={13}>
                                            No Records Founds
                                        </td>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {/* <Row className="mt-4 px-2">
              <Col md={5}>
                <div className="dataTables_info">
                  Showing {data ? (page - 1) * 10 + 1 : 0} to {data ? Math.min(data.data.total, page * 10) : 0} of {data ? data.data.total : 0}{" "}
                  entries
                </div>
              </Col>
              <Col md={7}>
                <Pagination total={data ? data?.data?.page : 0} setPage={setPage} page={page} />
              </Col>
            </Row> */}
                    </div>
                </>
            )}
            <Modal dialogClassName="modal-custom-sm" centered show={block} onHide={blockClose}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3">
                            <p className="modalTitle text-center mb-2 fw-bold">
                                Are you sure you want to {/* Display appropriate action based on the current state */}
                                {organiserData?.organizer_detail?.status !== 1 ? "unblock " : "block "}
                                this organiser?
                            </p>
                            <form className="custom_form">
                                <div className="btn-wrapper text-center">
                                    <Link className="btn btn-form btn-cancel" onClick={blockClose}>
                                        No
                                    </Link>
                                    <div
                                        className="btn btn-form btn-add"
                                        onClick={(e) => {
                                            handleBlockUser(e);
                                        }}
                                    >
                                        Yes
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ViewOrganizer;
