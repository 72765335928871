import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useLocation } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import Dummy from "../../assets/image/demo-image.png";
import Remove from "../../assets/image/rounded-remove.svg";
import { useApproveEventMutation, useDeleteEventImageMutation, useEventDetailsbyIdMutation } from "../../Services/api";
import Spinnerr from "../../Components/Spinner/Spinner";
import moment from "moment";
import { showToast } from "../../Services/tostify";
import { useDispatch } from "react-redux";
import EventUserBookingList from "./EventUserBookingList";

const EventDetails = ({ isNotification }) => {
    const location = useLocation();
    const dispatch = useDispatch();

    const [rejection, setRejection] = useState(false);

    const [rejectionReason, setRejectionReason] = useState("");

    const event_id = location.pathname.split("/")[3];

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);

    const [eventDetail, response] = useEventDetailsbyIdMutation();
    const [eventApprove, response2] = useApproveEventMutation();
    const [deleteImage, response3] = useDeleteEventImageMutation();

    const body = {
        event_id: event_id,
    };

    useEffect(() => {
        eventDetail(body);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!response.isLoading) {
            setLoading(false);
            setData(response?.data?.data);
            console.log(response?.data?.data)
            if (response.data?.data?.notification_unread_count > 0) {
            
               isNotification(response.data?.data?.notification_unread_count);
            
            }
        } else if (response.isError) {
            setLoading(false);
            setError(true);
        }
    }, [response]);

    const handleDeleteImage = (imageId) => {
        deleteImage(imageId);
    };

    const handleApproveOrganizer = async () => {
        const data = {
            event_id: event_id,
            action: "2", // 6 for approve
            reason: "", // No reason for approval
            delete_photos: [],
        };
        await eventApprove(data);
    };

    const handleSubmitRejection = async () => {
        const requestData = {
            event_id: event_id,
            action: "3", // 7 for reject
            reason: rejectionReason,
            delete_photos: [],
        };
        await eventApprove(requestData);
        // Handle success or display error message
        setRejection(false); // Close the rejection modal after submission
        setRejectionReason("");
    };

    useEffect(() => {
        if (!response2.isLoading && response2.isSuccess) {
            setRejectionReason("");
            eventDetail(body);
            dispatch(showToast(`Event request ${data?.event_detail?.status !== 2 ? "approved" : "rejected"}`, "SUCCESS_TOAST"));
        } else if (response2.isError && response2.status === "rejected") {
            console.log(response2.error);
            dispatch(showToast("Some Error Occured", "FAIL_TOAST"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response2]);

    useEffect(() => {
        if (!response3.isLoading && response3.isSuccess) {
            setRejectionReason("");
            eventDetail(body);
            dispatch(showToast(`Image Deleted`, "SUCCESS_TOAST"));
        } else if (response3.isError && response3.status === "rejected") {
            console.log(response3.error);
            dispatch(showToast(response3?.error?.data?.message, "FAIL_TOAST"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response3]);

    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "some error occured"
            ) : (
                <>
                    <Row className="justify-between">
                        <Col xl={2}>
                            <Link
                                className="bg-gray-800 hover:bg-gray-600 text-white outline-1 outline-double rounded-xl text-sm font-semibold px-4 py-2 d-inline-block"
                                to={"/event-management"}
                            >
                                Back
                            </Link>
                        </Col>
                        {data?.event_detail?.status !== 2 && data?.event_detail?.status !== 3 && (
                            <Col xl={3} className="flex gap-3 justify-end">
                                <Link
                                    className="bg-red-600 hover:bg-red-800 text-white outline-1 outline-double rounded-xl text-sm font-semibold px-4 py-2 d-inline-block"
                                    onClick={() => {
                                        setRejection(true);
                                    }}
                                >
                                    Reject
                                </Link>
                                <Link
                                    className="bg-green-600 hover:bg-green-800 text-white outline-1 outline-double rounded-xl text-sm font-semibold px-4 py-2 d-inline-block"
                                    onClick={() => {
                                        handleApproveOrganizer();
                                    }}
                                >
                                    Accept
                                </Link>
                            </Col>
                        )}
                    </Row>
                    <Row className="mt-4">
                        <Col xl={4}>
                            <div className="h-56 rounded-xl overflow-hidden relative">
                                <img
                                    className="d-block w-100 h-100 object-cover"
                                    src={data?.event_detail?.event_images[0].image || Dummy}
                                    alt={data?.event_detail?._id}
                                />
                                <Link className="absolute top-0 end-0 m-2 hover:opacity-80 w-5 h-5 rounded-full">
                                    <img src={Remove} alt="Remove" />
                                </Link>
                            </div>
                        </Col>
                        <Col xl={7}>
                            <span className="block text-xs text-gray-500">{data?.event_detail?.location || "-"}</span>
                            <div className="flex items-center justify-between mt-2">
                                <h6 className="text-lg font-bold">{data?.event_detail?.event_name || "-"}</h6>
                                <span className="text-base font-semibold">₹{data?.event_detail?.price || 0}</span>
                            </div>
                            <span className="block text-xs text-gray-500">
                            {moment(data?.event_detail?.date_time * 1000).utc().format("DD MMM YYYY, HH:mm A")}
                              

                            </span>
                            <p className="text-xs text-gray-600 font-medium mt-3 lh-base">{data?.event_detail?.description}</p>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        {data?.event_detail?.event_images.length > 0
                            ? data?.event_detail?.event_images.map(({ image, _id }, i) => (
                                  <Col xl={3} key={i}>
                                      <div className="h-36 rounded-xl overflow-hidden relative">
                                          <img className="d-block w-100 h-100 object-cover" src={image || Dummy} alt={_id} />
                                          <Link
                                              className="absolute top-0 end-0 m-2 hover:opacity-80 w-5 h-5 rounded-full"
                                              onClick={() => handleDeleteImage(_id)} // Pass the image ID to the delete function
                                          >
                                              <img src={Remove} alt="Remove" />
                                          </Link>
                                      </div>
                                  </Col>
                              ))
                            : ""}
                        {(data?.event_detail?.status === 2 || data?.event_detail?.status === 3) && <EventUserBookingList id={event_id} />}
                    </Row>
                </>
            )}

            <Modal size="sm" centered show={rejection} onHide={() => setRejection(false)}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body p-0">
                            <Form className="custom_form">
                                <div className="py-4 px-4">
                                    <p className="text-lg font-semibold text-center mb-3">Are you sure you want to reject this event?</p>
                                    <Form.Group className="mb-3 cf_label" controlId="rejectionReason">
                                        <Form.Control
                                            className="cf_input rounded-lg"
                                            as="textarea"
                                            rows={4}
                                            placeholder="Write your reason..."
                                            value={rejectionReason}
                                            onChange={(e) => setRejectionReason(e.target.value)}
                                        />
                                    </Form.Group>
                                    <div className="btn-wrapper text-center gap-3">
                                        <Link className="btn btn-form btn-cancel rounded-xl w-50" onClick={() => setRejection(false)}>
                                            Cancel
                                        </Link>
                                        <Link className="btn btn-form btn-add rounded-xl w-50" onClick={handleSubmitRejection}>
                                            Submit
                                        </Link>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EventDetails;
