import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/image/logo.svg";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useForgetPasswordMutation, useVerifyOtpMutation, useVerifyOtpQuery } from "../../Services/api";
import { useDispatch } from "react-redux";
import { showToast } from "../../Services/tostify";
const IconCheck = require("../../assets/image/icon-check.png");
const OtpSend = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const email = location.pathname.split("/")[2];
    const navigate = useNavigate();
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [forgetPassword, response] = useForgetPasswordMutation();
    const [checkOtp, response2] = useVerifyOtpMutation();

    const handleSubmit = (e) => {
        e.preventDefault();

        forgetPassword({
            email: email,
        });
    };

    useEffect(() => {
        if (!response.isLoading && response.isSuccess) {
            dispatch(showToast("OTP Send Successfully", "SUCCESS_TOAST"));
        } else if (response.isError && response.status === "rejected") {
            console.log(response.error);
            dispatch(showToast(response?.error?.data?.message, "FAIL_TOAST"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    const handleInputChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value.length === 1 && index < 3) {
            document.getElementById(`otp-${index + 1}`).focus();
        }
    };

    const verifyOtp = () => {
        const otpString = otp.join("");
        console.log("OTP:", otpString);
        const body = {
            email: email,
            otp: otpString,
        };
        checkOtp(body);
    };

    useEffect(() => {
        if (!response2.isLoading && response2.isSuccess) {
            dispatch(showToast("Otp Verified", "SUCCESS_TOAST"));
            navigate(`/reset-password/${email}`);
        } else if (response2.isError && response2.status === "rejected") {
            console.log(response2.error);
            dispatch(showToast(response2?.error?.data?.message, "FAIL_TOAST"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response2]);
    return (
        <>
            <div className="flex-center flex-column">
                <div>
                    <img className="" alt="logo" src={Logo} />
                </div>
                <div className="card login-wrapper border-0 mt-5">
                    <div className="card-header border-0 bg-transparent text-center">
                        <h4 className="text-xl font-bold">Otp Verification</h4>
                    </div>
                    <div className="card-body border-0 mt-3">
                        <form className="custom_form">
                            <div className="form-group">
                                <div className="d-flex gap-4">
                                    {[...Array(4)].map((_, index) => (
                                        <input
                                            key={index}
                                            type="number"
                                            value={otp[index]}
                                            className="inline-flex items-center justify-center w-25 h-25 py-3 text-center text-sm text-gray-900 bg-transparent rounded-xl outline-1 outline-double outline-gray-400 dark:text-dark dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                                            id={`otp-${index}`}
                                            placeholder=""
                                            maxLength="1"
                                            onChange={(e) => handleInputChange(index, e.target.value)}
                                        />
                                    ))}
                                </div>
                            </div>
                            <button
                                type="button"
                                onClick={verifyOtp}
                                className="text-white bg-red-600 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-xl text-base px-5 py-3.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 block w-100"
                            >
                                Verify OTP
                            </button>
                            <div className="form-group text-center">
                                <Link className="forgot-pass text-black" onClick={handleSubmit}>
                                    Resend OTP
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OtpSend;
