import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import Dummy from "../../assets/image/user-circle.svg";
import { MdBlock, MdStar, MdStarBorder, MdStarHalf } from "react-icons/md";
import { useGetSingleOrganiserQuery, useOrganiserReviewMutation } from "../../Services/api";
import Spinnerr from "../../Components/Spinner/Spinner";
import StarRating from "../../Components/Rating/StarRating";

const Review = ({ isNotification }) => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [organiserData, setOrganiserData] = useState(null);
    const [data, setData] = useState([]);
    const path = location.pathname.split("/review")[0];
    const id = location.pathname.split("/")[3];
    const [block, setBlock] = useState(false);
    const blockClose = () => setBlock(false);
    const blockShow = () => setBlock(true);
    const organiserDetails = useGetSingleOrganiserQuery(id, { refetchOnMountOrArgChange: true });
    const [getReview, response] = useOrganiserReviewMutation();

    const searcQuery = {
        organizer_id: id,
        page: 1,
        limit: 10,
        status: 2, // send 1 for unupproved review list, Send 2 to approved review list
    };
    useEffect(() => {
        getReview(searcQuery);
    }, []);
    useEffect(() => {
        if (!response.isLoading && !organiserDetails.isLoading) {
            setLoading(false);
            setData(response?.data?.data);
            setOrganiserData(organiserDetails?.data?.data);
            if (response.data?.data?.notification_unread_count > 0) {
            
               isNotification(response.data?.data?.notification_unread_count);
            
            }
        } else if (response.isError || organiserDetails.isError) {
            setError(true);
            setLoading(false);
        }
    }, [response, organiserDetails]);
    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "some error occured"
            ) : (
                <>
                    <Row>
                        <Col xl={2}>
                            <Link
                                className="bg-gray-800 hover:bg-gray-600 text-white outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 d-inline-block"
                                to={path}
                            >
                                Back
                            </Link>
                        </Col>
                        <Col xl={8}>
                            <div className="text-center">
                                <div className="inline-flex flex-col justify-center items-center">
                                    <img src={Dummy} alt="User" className="rounded-full" width="48" height="48" />
                                    <div className="inline-flex items-center mt-2 justify-center gap-1">
                                        <span className="text-2xl font-bold mb-0">{organiserData?.organizer_detail?.organizer_name}</span>
                                        <Link
                                            to={`/organizer-management/view-organizer/${id}/review`}
                                            className="text-xs inline-flex items-center gap-1 bg-amber-100 hover:text-gray-800 rounded-md py-1 px-2"
                                        >
                                            <MdStar className="text-amber-600 text-md" />
                                            {organiserData?.organizer_detail?.reviews}
                                        </Link>
                                    </div>
                                    <span className="text-base opacity-50 font-semibold">{organiserData?.organizer_detail?.business_email}</span>
                                </div>
                            </div>
                        </Col>
                        <Col xl={2} className="text-end">
                            <Link
                                className="text-stone-600 hover:text-stone-800 outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 inline-flex items-center justify-center gap-1"
                                onClick={blockShow}
                            >
                                <MdBlock /> Block
                            </Link>
                        </Col>
                    </Row>

                    <h6 className="text-base font-semibold mb-0 mt-4">Reviews</h6>
                    <Row>
                        <Col xl={4} className="mb-4">
                            <div className="flex items-center justify-between mt-1">
                                <div className="flex items-center gap-1">
                                    <span className="flex items-center gap-1">
                                        <StarRating value={4.5} />
                                    </span>
                                    <span className="text-xs">4.2 out of 5</span>
                                </div>
                                <span className="text-xs text-dark opacity-50">
                                    ({data?.average_review_detail?.average_review_count} total ratings)
                                </span>
                            </div>
                            <ul className="mt-2 flex flex-col gap-2">
                                <li className="flex items-center justify-between">
                                    <span className="text-xs text-dark opacity-50">Service</span>
                                    <span className="flex items-center gap-1">
                                        <StarRating value={data?.average_review_detail?.service_review} />
                                    </span>
                                </li>
                                <li className="flex items-center justify-between">
                                    <span className="text-xs text-dark opacity-50">Food</span>
                                    <span className="flex items-center gap-1">
                                        <StarRating value={data?.average_review_detail?.food_review} />
                                    </span>
                                </li>
                                <li className="flex items-center justify-between">
                                    <span className="text-xs text-dark opacity-50">Ambience</span>
                                    <span className="flex items-center gap-1">
                                        <StarRating value={data?.average_review_detail?.ambience_review} />
                                    </span>
                                </li>
                                <li className="flex items-center justify-between">
                                    <span className="text-xs text-dark opacity-50">Fan Club Interaction</span>
                                    <span className="flex items-center gap-1">
                                        <StarRating value={data?.average_review_detail?.fan_club_intraction} />
                                    </span>
                                </li>
                            </ul>
                            <ul className="mt-3 flex flex-col gap-2">
                                <li className="flex items-center justify-between gap-2">
                                    <span className="text-xs font-semibold">5 Star</span>
                                    <ProgressBar variant="warning" now={data?.average_rating_by_star?.five} className="flex-1 h-2" />
                                    <span className="text-xs font-semibold">{data?.average_rating_by_star?.five}%</span>
                                </li>
                                <li className="flex items-center justify-between gap-2">
                                    <span className="text-xs font-semibold">4 Star</span>
                                    <ProgressBar variant="warning" now={data?.average_rating_by_star?.four_five} className="flex-1 h-2" />
                                    <span className="text-xs font-semibold">{data?.average_rating_by_star?.four_five}%</span>
                                </li>
                                <li className="flex items-center justify-between gap-2">
                                    <span className="text-xs font-semibold">3 Star</span>
                                    <ProgressBar variant="warning" now={data?.average_rating_by_star?.three_four} className="flex-1 h-2" />
                                    <span className="text-xs font-semibold">{data?.average_rating_by_star?.three_four}%</span>
                                </li>
                                <li className="flex items-center justify-between gap-2">
                                    <span className="text-xs font-semibold">2 Star</span>
                                    <ProgressBar variant="warning" now={data?.average_rating_by_star?.two_three} className="flex-1 h-2" />
                                    <span className="text-xs font-semibold">{data?.average_rating_by_star?.two_three}%</span>
                                </li>
                                <li className="flex items-center justify-between gap-2">
                                    <span className="text-xs font-semibold">1 Star</span>
                                    <ProgressBar variant="warning" now={data?.average_rating_by_star?.one_two} className="flex-1 h-2" />
                                    <span className="text-xs font-semibold">{data?.average_rating_by_star?.one_two}%</span>
                                </li>
                            </ul>
                        </Col>
                        <Col xl={4} className="mb-4">
                            {data?.review_list.length > 0
                                ? data?.review_list.map(({}) => (
                                      <div className="bg-gray-200 rounded-lg p-3">
                                          <div className="flex items-center gap-2">
                                              <img src={Dummy} alt="User" className="rounded-full" width="22" height="22" />
                                              <h6 className="font-bold text-sm">Jason Statham</h6>
                                              <span className="flex items-center gap-1">
                                                  <MdStar className="text-amber-500 text-md" />
                                                  <MdStar className="text-amber-500 text-md" />
                                                  <MdStar className="text-amber-500 text-md" />
                                                  <MdStar className="text-gray-300 text-md" />
                                                  <MdStar className="text-gray-300 text-md" />
                                              </span>
                                          </div>
                                          <p className="text-xs font-medium line-clamp-3 mt-2 opacity-65">
                                              Lorem sipums dolro lorem sium dolro sir lotem ispum lorem ipsum dolro lorem ipsum dolor lorem dolor
                                              Lorem sipums dolro lorem sium dolro sir lotem ispum lorem ipsum dolro lorem ipsum dolor lorem dolor
                                          </p>
                                          <ul className="mt-3 flex flex-col gap-3">
                                              <li className="flex items-center justify-between">
                                                  <span className="text-xs text-dark opacity-50">Service</span>
                                                  <span className="flex items-center gap-1">
                                                      <MdStar className="text-amber-500 text-md" />
                                                      <MdStar className="text-amber-500 text-md" />
                                                      <MdStar className="text-amber-500 text-md" />
                                                      <MdStar className="text-gray-300 text-md" />
                                                      <MdStar className="text-gray-300 text-md" />
                                                  </span>
                                              </li>
                                              <li className="flex items-center justify-between">
                                                  <span className="text-xs text-dark opacity-50">Food</span>
                                                  <span className="flex items-center gap-1">
                                                      <MdStar className="text-amber-500 text-md" />
                                                      <MdStar className="text-amber-500 text-md" />
                                                      <MdStar className="text-amber-500 text-md" />
                                                      <MdStar className="text-gray-300 text-md" />
                                                      <MdStar className="text-gray-300 text-md" />
                                                  </span>
                                              </li>
                                              <li className="flex items-center justify-between">
                                                  <span className="text-xs text-dark opacity-50">Ambience</span>
                                                  <span className="flex items-center gap-1">
                                                      <MdStar className="text-amber-500 text-md" />
                                                      <MdStar className="text-amber-500 text-md" />
                                                      <MdStar className="text-amber-500 text-md" />
                                                      <MdStar className="text-gray-300 text-md" />
                                                      <MdStar className="text-gray-300 text-md" />
                                                  </span>
                                              </li>
                                              <li className="flex items-center justify-between">
                                                  <span className="text-xs text-dark opacity-50">Fan Club Interaction</span>
                                                  <span className="flex items-center gap-1">
                                                      <MdStar className="text-amber-500 text-md" />
                                                      <MdStar className="text-amber-500 text-md" />
                                                      <MdStar className="text-amber-500 text-md" />
                                                      <MdStar className="text-gray-300 text-md" />
                                                      <MdStar className="text-gray-300 text-md" />
                                                  </span>
                                              </li>
                                          </ul>
                                      </div>
                                  ))
                                : "No Review Available"}
                        </Col>
                    </Row>
                </>
            )}
            <Modal dialogClassName="modal-custom-sm" centered show={block} onHide={blockClose}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3">
                            <p className="modalTitle text-center mb-2 fw-bold">
                                Are you sure you want to
                                <br />
                                block this User?
                            </p>
                            <form className="custom_form">
                                <div className="btn-wrapper text-center">
                                    <Link className="btn btn-form btn-cancel" onClick={blockClose}>
                                        No
                                    </Link>
                                    <Link className="btn btn-form btn-add" onClick={blockClose}>
                                        Yes
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Review;
