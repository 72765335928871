import { useEffect, useState } from "react";

import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAddPenalityMutation, useGetPenalityQuery } from "../../Services/api";
import { showToast } from "../../Services/tostify";
import { useDispatch } from "react-redux";
const Penality = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState({
        percentage: "",
    });
    const [newData, setNewData] = useState({
        percentage: "",
    });
    const response = useGetPenalityQuery(data, { refetchOnMountOrArgChange: true });
    const [addPenality, response2] = useAddPenalityMutation();

    useEffect(() => {
        if (!response.isLoading) {
            setData({ percentage: response?.data?.data?.cancellation_percentage });
            setNewData({ percentage: response?.data?.data?.cancellation_percentage });
            setLoading(false);
        } else if (response.isError) {
            setLoading(false);
            setError(true);
        }
    }, [response]);

    const handleAddPenalty = async () => {
        await addPenality(newData);
    };

    useEffect(() => {
        if (!response2.isLoading && response2.isSuccess) {
            response.refetch();
            setLoading(false);
            dispatch(showToast("Penalty Updated", "SUCCESS_TOAST"));
        } else if (response2.isError || response2.status === "rejected") {
            setLoading(false);
            setError(true);
            dispatch(showToast(response?.error?.data?.message, "FAIL_TOAST"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response2]);

    return (
        <>
            {loading ? (
                <Spinner animation="border" />
            ) : error ? (
                "some error occured"
            ) : (
                <>
                    <div className="row col-md-3 mx-auto my-5">
                        <div className="col-md-12">
                            <div className="form-group">
                                <div className="relative pos-r">
                                    <input
                                        type="number"
                                        className="block px-2.5 pe-5 pb-2.5 pt-4 w-full text-sm text-black-900 bg-transparent rounded-xl outline-1 outline-double outline-gray-400 dark:text-black dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                                        id="email"
                                        value={newData.percentage}
                                        onChange={(e) => setNewData({ ...data, percentage: e.target.value })}
                                        placeholder=""
                                    />
                                    <span className="position-absolute end-5 top-0 bottom-0 m-auto flex align-middle items-center">%</span>
                                    <label
                                        htmlFor="floating_outlined"
                                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-blue-500 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                    >
                                        penalty percentage
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 text-center mt-3">
                            <Link
                                className="text-white bg-red-600 hover:bg-red-800 outline-1 outline-double rounded-3 text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                onClick={(e) => handleAddPenalty()}
                            >
                                Save
                            </Link>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Penality;
