import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useLocation } from "react-router-dom";
import User from "../../assets/image/user-circle.svg";
import Img from "../../assets/image/user-circle.svg";
import Country1 from "../../assets/image/country1.png";
import Country2 from "../../assets/image/country2.png";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

const Leaderboard = () => {
    const location = useLocation();
    const path = location.pathname.split("");
    const totalPage = 2;
    const [page, setPage] = useState(0);
    return (
        <>
            <Row>
                <Col xl={2}>
                    <Link
                        className="bg-gray-800 hover:bg-gray-600 text-white outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 d-inline-block"
                        to={"/user-management"}
                    >
                        Back
                    </Link>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col xl={4}>
                    <div className="bg-gray-200 rounded-lg px-4 py-3 flex items-center justify-between flex-col">
                        <span className="text-red-600 uppercase text-sm font-medium inline-block ms-auto">&#x2022; LIVE</span>
                        <span>
                            <img src={User} alt="" />
                        </span>
                        <div className="flex items-center justify-between w-100 my-3">
                            <span className="text-sm font-bold flex items-center justify-center gap-2">
                                <img src={Country1} alt="" /> SWE
                            </span>
                            <span className="text-sm font-bold">Vs</span>
                            <span className="text-sm font-bold flex items-center justify-center gap-2">
                                <img src={Country2} alt="" /> SVN
                            </span>
                        </div>
                        <span className="text-base font-bold">Score: 0 - 2</span>
                    </div>
                </Col>
                <Col xl={6}>
                    <div className="common-table-wrapper m-0">
                        <div className="table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th>All Participants (800)</th>
                                        <th>Points</th>
                                        <th>Rank</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="flex gap-2 items-center">
                                                <img src={Img} width="15" height="15" className="rounded-full" alt="Dummy" />
                                                Jason Statham
                                            </span>
                                        </td>
                                        <td>312</td>
                                        <td>
                                            <span className="inline-flex items-center">
                                                #200 <MdArrowDropDown className="text-lg text-red-700" />
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="flex gap-2 items-center">
                                                <img src={Img} width="15" height="15" className="rounded-full" alt="Dummy" />
                                                Christopher Jay
                                            </span>
                                        </td>
                                        <td>312</td>
                                        <td>
                                            <span className="inline-flex items-center">
                                                #200 <MdArrowDropUp className="text-lg text-green-700" />
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="flex gap-2 items-center">
                                                <img src={Img} width="15" height="15" className="rounded-full" alt="Dummy" />
                                                Ronnie Coleman
                                            </span>
                                        </td>
                                        <td>312</td>
                                        <td>
                                            <span className="inline-flex items-center">
                                                #200 <MdArrowDropDown className="text-lg text-red-700" />
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="flex gap-2 items-center">
                                                <img src={Img} width="15" height="15" className="rounded-full" alt="Dummy" />
                                                Zlatan Ibrahimović
                                            </span>
                                        </td>
                                        <td>312</td>
                                        <td>
                                            <span className="inline-flex items-center">
                                                #200 <MdArrowDropUp className="text-lg text-green-700" />
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="flex gap-2 items-center">
                                                <img src={Img} width="15" height="15" className="rounded-full" alt="Dummy" />
                                                Ramsey Thumps
                                            </span>
                                        </td>
                                        <td>312</td>
                                        <td>
                                            <span className="inline-flex items-center">
                                                #200 <MdArrowDropUp className="text-lg text-green-700" />
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="flex gap-2 items-center">
                                                <img src={Img} width="15" height="15" className="rounded-full" alt="Dummy" />
                                                Paul Allen
                                            </span>
                                        </td>
                                        <td>312</td>
                                        <td>
                                            <span className="inline-flex items-center">
                                                #200 <MdArrowDropUp className="text-lg text-green-700" />
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="flex gap-2 items-center">
                                                <img src={Img} width="15" height="15" className="rounded-full" alt="Dummy" />
                                                Jason Statham
                                            </span>
                                        </td>
                                        <td>312</td>
                                        <td>
                                            <span className="inline-flex items-center">
                                                #200 <MdArrowDropDown className="text-lg text-red-700" />
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="flex gap-2 items-center">
                                                <img src={Img} width="15" height="15" className="rounded-full" alt="Dummy" />
                                                Maria Samantha
                                            </span>
                                        </td>
                                        <td>312</td>
                                        <td>
                                            <span className="inline-flex items-center">
                                                #200 <MdArrowDropDown className="text-lg text-red-700" />
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="flex gap-2 items-center">
                                                <img src={Img} width="15" height="15" className="rounded-full" alt="Dummy" />
                                                Deren Sammy
                                            </span>
                                        </td>
                                        <td>312</td>
                                        <td>
                                            <span className="inline-flex items-center">
                                                #200 <MdArrowDropDown className="text-lg text-red-700" />
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="flex gap-2 items-center">
                                                <img src={Img} width="15" height="15" className="rounded-full" alt="Dummy" />
                                                Larry Wheels
                                            </span>
                                        </td>
                                        <td>312</td>
                                        <td>
                                            <span className="inline-flex items-center">
                                                #200 <MdArrowDropDown className="text-lg text-red-700" />
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="flex gap-2 items-center">
                                                <img src={Img} width="15" height="15" className="rounded-full" alt="Dummy" />
                                                Johnny Bravo
                                            </span>
                                        </td>
                                        <td>312</td>
                                        <td>
                                            <span className="inline-flex items-center">
                                                #200 <MdArrowDropDown className="text-lg text-red-700" />
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="flex gap-2 items-center">
                                                <img src={Img} width="15" height="15" className="rounded-full" alt="Dummy" />
                                                Jason Statham
                                            </span>
                                        </td>
                                        <td>312</td>
                                        <td>
                                            <span className="inline-flex items-center">
                                                #200 <MdArrowDropDown className="text-lg text-red-700" />
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Leaderboard;
