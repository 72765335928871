import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { InputGroup, Modal, Form, Row, Col } from "react-bootstrap";
import Dummy from "../../assets/image/user-circle.svg";

import Remove from "../../assets/image/rounded-remove.svg";
import { MdBlock, MdCheck, MdClose } from "react-icons/md";
import { useDeleteOrganiserImageMutation, useGetSingleOrganiserQuery, useOrganiserApproveMutation } from "../../Services/api";
import Spinnerr from "../../Components/Spinner/Spinner";
import { showToast } from "../../Services/tostify";
import { useDispatch } from "react-redux";

const ViewRequest = ({ isNotification }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [status, setStatus] = useState(null);
    const id = location.pathname.split("/")[3];
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const [deleteImageIds, setDeleteImageIds] = useState([]);
    const [block, setBlock] = useState(false);
    const blockClose = () => setBlock(false);
    const blockShow = () => {
        dispatch(showToast("This feature comin soon", "INFO_TOAST"));
    };
    // const blockShow = () => setBlock(true);

    const [rejectionReason, setRejectionReason] = useState("");
    const [rejection, setRejection] = useState(false);
    const rejectionShow = () => setRejection(true);
    // const rejectionClose = () => setRejection(false);

    const approveShow = () => setApprove(true);
    const approveClose = () => setApprove(false);
    const [percentage, setPercentage] = useState("");
    const [approve, setApprove] = useState(false);
    const singleOrganizer = useGetSingleOrganiserQuery(id, { refetchOnMountOrArgChange: true });
    const [deleteImage, response3] = useDeleteOrganiserImageMutation();

    const [organiserAprrove, response] = useOrganiserApproveMutation();
    const handleDeleteImage = (imageId) => {
        deleteImage(imageId);
    };

    useEffect(() => {
        if (!singleOrganizer.isLoading) {
            setLoading(false);
            setData(singleOrganizer?.data?.data);
            if (response.data?.data?.notification_unread_count > 0) {
            
               isNotification(response.data?.data?.notification_unread_count);
            
            }
        } else if (singleOrganizer.isError) {
            setLoading(false);
            setError(true);
        }
    }, [singleOrganizer]);
    const handleApprove = (organizerId) => {
        approveShow();
    };
    const handleReject = (organizerId) => {
        rejectionShow();
    };
    const handleApproveOrganizer = async () => {
        const data = {
            organizer_id: id,
            percent: percentage,
            status: "6", // 6 for approve
            reason: "", // No reason for approval
            delete_photos: deleteImageIds,
        };
        await organiserAprrove(data);
        // Handle success or display error message
        // await singleOrganizer.refetch();
        setApprove(false);
        setPercentage("");
    };
    const handleSubmitRejection = async () => {
        const data = {
            organizer_id: id,
            percent: "10",
            status: "7", // 7 for reject
            reason: rejectionReason,
            delete_photos: deleteImageIds,
        };
        await organiserAprrove(data);
        // Handle success or display error message
        setRejection(false);
        setPercentage("");
    };
    useEffect(() => {
        if (!response.isLoading && response.isSuccess) {
            setRejectionReason("");
            setPercentage("");
            singleOrganizer.refetch();
            dispatch(showToast(`organiser request ${status !== 7 ? "approved" : "rejected"}`, "SUCCESS_TOAST"));
        } else if (response.isError && response.status === "rejected") {
            console.log(response.error);
            dispatch(showToast(response3?.error?.data?.message, "FAIL_TOAST"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    useEffect(() => {
        if (!response3.isLoading && response3.isSuccess) {
            setRejectionReason("");
            singleOrganizer.refetch();
            dispatch(showToast(`Image Deleted`, "SUCCESS_TOAST"));
        } else if (response3.isError && response3.status === "rejected") {
            console.log(response3.error);
            dispatch(showToast(response3?.error?.data?.message, "FAIL_TOAST"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response3]);

    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "Some Error Occured"
            ) : (
                <>
                    <Row>
                        <Col xl={2}>
                            <Link
                                className="bg-gray-800 hover:bg-gray-600 text-white outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 d-inline-block"
                                to={"/organizer-management"}
                            >
                                Back
                            </Link>
                        </Col>
                        <Col xl={8}>
                            <div className="text-center">
                                <div className="inline-flex flex-col justify-center items-center">
                                    <img src={Dummy} alt="User" className="rounded-full" width="48" height="48" />
                                    <span className="text-2xl font-bold mb-0 mt-2">{data?.organizer_detail?.organizer_name}</span>
                                    <span className="text-base opacity-50 font-semibold">{data?.organizer_detail?.business_email}</span>
                                    <span className="text-base opacity-50 font-semibold mt-2">{data?.organizer_detail?.location}</span>
                                </div>
                            </div>
                        </Col>
                        <Col xl={2} className="text-end"></Col>
                    </Row>
                    <Row className="mt-4 col-10 mx-auto justify-center">
                        {data?.organizer_detail?.business_images.length > 0 ? (
                            data?.organizer_detail?.business_images.map(({ image, _id }, i) => (
                                <Col xl={2} key={i}>
                                    <div className="h-32 rounded-xl overflow-hidden relative">
                                        <img className="d-block w-100 h-100 object-cover" src={image} alt={_id} />
                                        <Link
                                            className="absolute top-0 end-0 m-2 hover:opacity-80 w-5 h-5 rounded-full"
                                            onClick={() => handleDeleteImage(_id)}
                                        >
                                            <img src={Remove} alt="Remove" />
                                        </Link>
                                    </div>
                                </Col>
                            ))
                        ) : (
                            <Col xl={2}>
                                <div className="h-32 rounded-xl overflow-hidden relative">
                                    <p>No Images Available</p>
                                </div>
                            </Col>
                        )}
                    </Row>
                    <Row className="mt-4">
                        <Col className="col-md-5 mx-auto flex gap-3 justify-center">
                            <Link
                                className="text-red-600 hover:text-red-800 outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 inline-flex items-center justify-center gap-1"
                                onClick={() => {
                                    handleReject(id);
                                    setStatus(7);
                                }}
                            >
                                <MdClose /> Reject
                            </Link>
                            {data?.organizer_detail?.status !== 6 ? (
                                <Link
                                    className="text-green-600 hover:text-green-800 outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 inline-flex items-center justify-center gap-1"
                                    onClick={() => {
                                        handleApprove(id);
                                        setStatus(6);
                                    }}
                                >
                                    <MdCheck /> Accept
                                </Link>
                            ) : (
                                ""
                            )}
                        </Col>
                    </Row>
                </>
            )}

            <Modal dialogClassName="modal-custom-sm" centered show={block} onHide={blockClose}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3">
                            <p className="modalTitle text-center mb-2 fw-bold">
                                Are you sure you want to
                                <br />
                                block this User?
                            </p>
                            <form className="custom_form">
                                <div className="btn-wrapper text-center">
                                    <Link className="btn btn-form btn-cancel" onClick={blockClose}>
                                        No
                                    </Link>
                                    <Link className="btn btn-form btn-add" onClick={blockClose}>
                                        Yes
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* <Modal size="sm" centered show={rejection} onHide={rejectionClose}>
        <Modal.Body className="p-0">
          <div className="card logout-card common_box">
            <div className="card-body p-0">
              <Form className="custom_form">
                <div className="py-4 px-4">
                  <p className="text-lg font-semibold text-center mb-3">Enter your reason for rejection</p>

                  <Form.Group className="mb-3 cf_label" controlId="Entry Fee">
                    <Form.Control className="cf_input rounded-lg" as="textarea" rows={4} placeholder="Write your reason..." value="" />
                  </Form.Group>

                  <div className="btn-wrapper text-center gap-3">
                    <Link className="btn btn-form btn-cancel rounded-xl w-50" onClick={rejectionClose}>
                      Cancel
                    </Link>
                    <Link className="btn btn-form btn-add rounded-xl w-50" onClick={rejectionClose}>
                      Submit
                    </Link>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
            {/* Reject Modal */}
            <Modal size="sm" centered show={rejection} onHide={() => setRejection(false)}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body p-0">
                            <Form className="custom_form">
                                <div className="py-4 px-4">
                                    <p className="text-lg font-semibold text-center mb-3">Enter your reason for rejection</p>
                                    <Form.Group className="mb-3 cf_label" controlId="rejectionReason">
                                        <Form.Control
                                            className="cf_input rounded-lg"
                                            as="textarea"
                                            rows={4}
                                            placeholder="Write your reason..."
                                            value={rejectionReason}
                                            onChange={(e) => setRejectionReason(e.target.value)}
                                        />
                                    </Form.Group>
                                    <div className="btn-wrapper text-center gap-3">
                                        <Link className="btn btn-form btn-cancel rounded-xl w-50" onClick={() => setRejection(false)}>
                                            Cancel
                                        </Link>
                                        <Link className="btn btn-form btn-add rounded-xl w-50" onClick={handleSubmitRejection}>
                                            Submit
                                        </Link>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Approve Modal */}
            <Modal size="sm" centered show={approve} onHide={approveClose}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body p-0">
                            <Form className="custom_form">
                                <div className="py-4 px-4">
                                    <p className="text-lg font-semibold text-center mb-3">
                                        Enter the percentage that will be deducted from organizer's earnings
                                    </p>

                                    <Form.Group className="mb-3 cf_label" controlId="Entry Fee">
                                        <InputGroup>
                                            <Form.Control
                                                className="cf_input rounded-lg"
                                                type="number"
                                                placeholder="Percentage"
                                                style={{ borderRight: 0 }}
                                                value={percentage}
                                                onChange={(e) => setPercentage(e.target.value)}
                                            />
                                            <InputGroup.Text
                                                style={{
                                                    background: "none",
                                                    border: "1px solid #C1C1C1",
                                                    padding: "0px 16px 0 0",
                                                    borderRadius: "0 15px 15px 0",
                                                }}
                                            >
                                                <span style={{ marginLeft: "5px", fontSize: "14px", lineHeight: "21px", color: "#000" }}>%</span>
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                    <div className="btn-wrapper text-center gap-3">
                                        <Link className="btn btn-form btn-cancel rounded-xl w-50" onClick={approveClose}>
                                            Cancel
                                        </Link>
                                        <Link className="btn btn-form btn-add rounded-xl w-50" onClick={handleApproveOrganizer}>
                                            Submit
                                        </Link>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ViewRequest;
