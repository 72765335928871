import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import AboutUs from "./AboutUs";

import ContactUs from "./ContactUs";
import CancellationPolicy from "./CancellationPolicy";
import DeactivationPolicy from "./DeactivationPolicy";

import TermsNCondition from "./TermsNCondition/TermsNCondition";
import Faqs from "./Faqs/Faqs";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";

const CMS = ({ isNotification }) => {
    return (
        <>
            <Tabs defaultActiveKey="termConditional" transition={true} id="noanim-tab-example" className="mb-3 nav-pills-tabs" variant="pills">
                <Tab eventKey="termConditional" title="Terms & Conditions">
                    <TermsNCondition isNotification={isNotification} />
                </Tab>

                <Tab eventKey="faqs" title="FAQs">
                    <Faqs />
                </Tab>
                <Tab eventKey="aboutUs" title="About Us">
                    <AboutUs />
                </Tab>

                <Tab eventKey="privacyPolicy" title="Privacy Policy">
                    <PrivacyPolicy />
                </Tab>

                <Tab eventKey="contactUs" title="Contact us">
                    <ContactUs />
                </Tab>

                <Tab eventKey="cancellationPolicy" title="Cancellation Policy">
                    <CancellationPolicy />
                </Tab>
                <Tab eventKey="deactivationPolicy" title="Deactivation Policy">
                    <DeactivationPolicy />
                </Tab>
            </Tabs>
        </>
    );
};

export default CMS;
