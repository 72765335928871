import React from "react";
import { Link } from "react-router-dom";

function Pagination({ setPage, total, nextPage, page }) {
  const handleNext = () => {
    if (nextPage !== null) {
      setPage(nextPage);
    }
  };

  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <ul className="pagination mb-0 justify-content-end">
      <li className="paginate_button page-item previous">
        <Link className="page-link" onClick={handlePrev}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0V0z"></path>
            <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
          </svg>
        </Link>
      </li>
      <div className="text-muted total-text d-flex align-items-center fw-bold">
        <span className="mx-1 text-dark">{page}</span>
        <span className="total-middle-text text-dark">of</span>
        <span className="mx-1 text-dark">{total}</span>
      </div>
      <li className="paginate_button page-item next">
        <Link className="page-link" onClick={handleNext}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0V0z"></path>
            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
          </svg>
        </Link>
      </li>
    </ul>
  );
}

export default Pagination;
