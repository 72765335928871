/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Dummy from "../../assets/image/user-circle.svg";
import Demo from "../../assets/image/demo-image.png";
import Pagination from "../../Components/Pagination/Pagination";
import View from "../../assets/image/view.svg";
import { MdBlock, MdStar } from "react-icons/md";
import { useEventListMutation, useGetSingleOrganiserQuery, useMarkAsPaidMutation } from "../../Services/api";
import Spinnerr from "../../Components/Spinner/Spinner";
import moment from "moment";
import { useDispatch } from "react-redux";
import { showToast } from "../../Services/tostify";

const PaymentDetail = ({ isNotification }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const id = location.pathname.split("/")[3];
    const [loading, setLoading] = useState(true);
    //   const [paymentId, setPaymentId] = useState(null);
    const [error, setError] = useState(false);
    const [block, setBlock] = useState(false);
    const [page, setPage] = useState(1); // Initialize page state to start from 0
    const [totalPages, setTotalPages] = useState(1);
    const [nextPage, setNextPage] = useState(null);
    const [data, setData] = useState(null);
    const [organiserData, setOrganiserData] = useState(null);
    const [selectedEventIds, setSelectedEventIds] = useState([]);
    const [searchQuery, setSearchQuery] = useState({
        page: 1,
        limit: 10,
        search: "",
        sort: {
            createdAt: -1,
        },
        organizer_id: id,
        mark_as_paid: false,
    });
    const singleOrganizer = useGetSingleOrganiserQuery(id, { refetchOnMountOrArgChange: true });
    const [eventList, response] = useEventListMutation();
    const [markAsPaid, response2] = useMarkAsPaidMutation();
    useEffect(() => {
        eventList(searchQuery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    useEffect(() => {
        if (!response.isLoading && response.isSuccess && !singleOrganizer.isLoading) {
            setLoading(false);
            setData(response?.data?.data);
            setOrganiserData(singleOrganizer?.data?.data);
            setPage(response?.data?.data?.page);
            setTotalPages(response?.data?.data?.total_pages);
            setNextPage(response?.data?.data?.next_page);
            if (response.data?.data?.notification_unread_count > 0) {

                isNotification(response.data?.data?.notification_unread_count);

            }
        } else if (response.isError && response.status === "rejected" && !singleOrganizer.isError) {
            setError(true);
            setLoading(false);
        }
    }, [response, singleOrganizer]);

    useEffect(() => {
        if (!response2.isLoading && response2.isSuccess) {
            setLoading(false);
            // Clear selected event IDs after marking as paid
            setSelectedEventIds([]);

            // Refresh event list
            eventList(searchQuery);
            dispatch(showToast("Paid Status updated", "SUCCESS_TOAST"));
        } else if (response2.isError && response2.status === "rejected") {
            setError(true);
            setLoading(false);
        }
    }, [response2]);

    const handleMarkAsPaid = () => {
        markAsPaid({ organizer_id: id, event_ids: selectedEventIds });
    };

    const toggleSelectEvent = (eventId) => {
        if (selectedEventIds.includes(eventId)) {
            setSelectedEventIds(selectedEventIds.filter((id) => id !== eventId));
        } else {
            setSelectedEventIds([...selectedEventIds, eventId]);
        }
    };

    const handleMarkAllAsPaid = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            const allEventIds = data?.event_list.map((event) => event._id) || [];
            setSelectedEventIds(allEventIds);
        } else {
            setSelectedEventIds([]);
        }
    };
    // console.log(selectedEventIds);
    return (
        <>
            {response.isLoading && <Spinnerr />}
            {response.error && <div>Error: {response.error.message}</div>}
            {!response.isLoading && !response.error && organiserData && data && (
                <>
                    <Row>
                        <Col xl={2}>
                            <Link
                                className="bg-gray-800 hover:bg-gray-600 text-white outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 d-inline-block"
                                to={"/payment-management"}
                            >
                                Back
                            </Link>
                        </Col>
                        <Col xl={8}>
                            <div className="text-center">
                                <div className="inline-flex flex-col justify-center items-center">
                                    <img
                                        src={organiserData?.organizer_detail?.image || Dummy}
                                        alt="User"
                                        className="rounded-full"
                                        width="48"
                                        height="48"
                                    />
                                    <div className="inline-flex items-center mt-2 justify-center gap-1">
                                        <span className="text-2xl font-bold mb-0">{organiserData?.organizer_detail?.organizer_name}</span>
                                        <Link
                                            to={`/organizer-management/view-organizer/${id}/review`}
                                            className="text-xs inline-flex items-center gap-1 bg-amber-100 hover:text-gray-800 rounded-md py-1 px-2"
                                        >
                                            <MdStar className="text-amber-600 text-md" /> {organiserData?.organizer_detail?.reviews.toFixed(2)}
                                        </Link>
                                    </div>
                                    <span className="text-base opacity-50 font-semibold">{organiserData?.organizer_detail?.business_email}</span>
                                </div>
                            </div>
                        </Col>
                        <Col xl={2} className="text-end"></Col>
                    </Row>

                    <div className="common-table-wrapper m-0 mt-5">
                        <Row className="mb-1 items-center">
                            <Col md={6}>
                                <h6 className="text-base font-semibold mb-0">Events</h6>
                            </Col>
                            <Col md={6} className="text-end">
                                <div className="inline-flex items-center justify-end gap-3">
                                    <button
                                        className=" text-sky-600 hover:text-sky-800  outline-1 outline-double rounded-full text-sm font-semibold px-4 py-2 d-inline-block"
                                        onClick={handleMarkAsPaid}
                                    >
                                        Mark all as paid
                                    </button>
                                    <button
                                        className="text-sky-600 hover:text-sky-800  outline-1 outline-double rounded-full text-sm font-semibold px-4 py-2 d-inline-block"
                                        onClick={handleMarkAsPaid}
                                    >
                                        Mark selected as paid
                                    </button>
                                </div>
                            </Col>
                        </Row>
                        <div className="table-responsive mt-4">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                id="comments"
                                                name="comments"
                                                type="checkbox"
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 align-middle"
                                                // onClick={"on this button click i want to select all listed checkbox nad put their ids to the state"}
                                                onClick={handleMarkAllAsPaid}
                                            />
                                        </th>
                                        <th>Sr.No.</th>
                                        <th>Name</th>
                                        <th>Organizer</th>
                                        <th>Location</th>
                                        <th>Status</th>
                                        <th>Cancellation Penalty</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>
                                            Event
                                            <br />
                                            Amount
                                        </th>
                                        <th>
                                            Amount
                                            <br />
                                            Generated
                                        </th>
                                        <th>My Cut</th>
                                        <th>Tax</th>
                                        <th>
                                            Amount To
                                            <br />
                                            Distribute
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {data?.event_list.length > 0 ? (
                                        data.event_list.map(
                                            (
                                                {
                                                    _id,
                                                    event_name,
                                                    organizer_detail,
                                                    status_tag,
                                                    location,
                                                    createdAt,
                                                    total_revenue,
                                                    my_cut,
                                                    price,
                                                    total_tax,
                                                    amount_to_distribute,
                                                    cancellation_penalty,
                                                },
                                                i,
                                            ) => (
                                                <tr key={i}>
                                                    <td>
                                                        <td>
                                                            <input
                                                                id={`event_${_id}`}
                                                                name={`event_${_id}`}
                                                                type="checkbox"
                                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 align-middle"
                                                                checked={selectedEventIds.includes(_id)}
                                                                onChange={() => toggleSelectEvent(_id)}
                                                            />
                                                        </td>
                                                    </td>
                                                    <td>{(page - 1) * 10 + i + 1}</td>
                                                    <td>{event_name}</td>
                                                    <td>{organizer_detail?.organizer_name}</td>
                                                    <td>{location}</td>
                                                    <td>{status_tag}</td>
                                                    <td>₹ {cancellation_penalty !== null ? status_tag === "cancelled" ? cancellation_penalty.toFixed(2) : 0 : 0}</td>
                                                    <td>{moment(createdAt).format("MM/DD/YYYY")}</td>
                                                    <td>{moment(createdAt).format("HH : mm A")}</td>
                                                    <td>₹ {price}</td>
                                                    <td>₹{total_revenue !== null ? total_revenue.toFixed(2) : 0}</td>
                                                    <td>₹{my_cut !== null ? my_cut.toFixed(2) : 0}</td>
                                                    <td>₹{total_tax !== null ? total_tax.toFixed(2) : 0}</td>
                                                    <td>₹{amount_to_distribute !== null ? status_tag === "cancelled" ? 0 : amount_to_distribute.toFixed(2) : 0}</td>
                                                </tr>
                                            ),
                                        )
                                    ) : (
                                        <td className="text-center" colSpan={13}>
                                            No Records Founds
                                        </td>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {data?.event_list.length > 0 ? (
                            <Row className="mt-4 px-2">
                                <Col md={5}>
                                    <div className="dataTables_info">
                                        {`Showing ${Math.min((page - 1) * 10 + 1, data?.total)}` +
                                            ` to ${Math.min(page * 10, data?.total) || 0} of ${data?.total || 0} entries`}
                                    </div>
                                </Col>
                                <Col md={7}>
                                    <Pagination
                                        total={totalPages}
                                        setPage={(newPage) => {
                                            setSearchQuery((prevQuery) => ({
                                                ...prevQuery,
                                                page: newPage,
                                            }));
                                        }}
                                        page={page} // Add 1 to page state to show correct page number
                                        nextPage={nextPage} // Pass the next page number to the Pagination component
                                    />
                                </Col>
                            </Row>
                        ) : (
                            ""
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default PaymentDetail;
