/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { MdDelete, MdModeEdit } from "react-icons/md";
import Spinnerr from "../../../Components/Spinner/Spinner";
import { showToast } from "../../../Services/tostify";
import { useAddFaqsMutation, useDeleteFaqsMutation, useEditFaqByIdMutation, useGetFaqByIdQuery, useGetFaqsQuery } from "../../../Services/api";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

const UserFaqs = () => {
    const dispatch = useDispatch();
    const [faqData, setFaqData] = useState([]);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [newFaq, setNewFaq] = useState({ question: "", answer: "", role_type: "user" });
    const [editFaq, setEditFaq] = useState({ id: "", question: "", answer: "", role_type: "user" });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [selectedFaqId, setSelectedFaqId] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal
    const [deleteId, setDeleteId] = useState(null);

    const body = {
        role_type: "user",
    };
    const faqList = useGetFaqsQuery(body, { refetchOnMountOrArgChange: true });
    const singlebody = {
        id: selectedFaqId,
        role_type: "user",
    };
    const getFaqById = useGetFaqByIdQuery(singlebody, { refetchOnMountOrArgChange: true });
    const [addFaqMutation, addFaqResponse] = useAddFaqsMutation();
    const [editFaqMutation, editFaqResponse] = useEditFaqByIdMutation();
    const [deleteFaqMutation, deleteFaqResponse] = useDeleteFaqsMutation();

    useEffect(() => {
        if (faqList.isSuccess) {
            setFaqData(faqList.data.data);
            setLoading(false);
        } else if (faqList.isError) {
            setError(true);
            setLoading(false);
        }
    }, [faqList]);

    useEffect(() => {
        if (getFaqById.isSuccess) {
            setEditFaq({ ...editFaq, question: getFaqById?.data?.data?.question, answer: getFaqById?.data?.data?.answer });
            setLoading(false);
        } else if (getFaqById.isError) {
            setError(true);
            setLoading(false);
        }
    }, [getFaqById]);

    useEffect(() => {
        if (!addFaqResponse.isLoading && addFaqResponse.isSuccess) {
            setShowAddForm(false);
            setNewFaq({ question: "", answer: "" });
            faqList.refetch();
            dispatch(showToast("FAQ Added", "SUCCESS_TOAST"));
        } else if (addFaqResponse.isError && addFaqResponse.status === "rejected") {
            dispatch(showToast(addFaqResponse?.error?.data?.message, "FAIL_TOAST"));
        }
    }, [addFaqResponse]);

    useEffect(() => {
        if (!editFaqResponse.isLoading && editFaqResponse.isSuccess) {
            setShowEditForm(false);
            setEditFaq({ id: "", question: "", answer: "" });
            faqList.refetch();
            dispatch(showToast("FAQ Updated", "SUCCESS_TOAST"));
        } else if (editFaqResponse.isError && editFaqResponse.status === "rejected") {
            dispatch(showToast(editFaqResponse?.error?.data?.message, "FAIL_TOAST"));
        }
    }, [editFaqResponse]);

    useEffect(() => {
        if (!deleteFaqResponse.isLoading && deleteFaqResponse.isSuccess) {
            faqList.refetch();
            setShowDeleteModal(false);
            dispatch(showToast("FAQ Deleted", "SUCCESS_TOAST"));
        } else if (deleteFaqResponse.isError && deleteFaqResponse.status === "rejected") {
            dispatch(showToast(deleteFaqResponse?.error?.data?.message, "FAIL_TOAST"));
        }
    }, [deleteFaqResponse]);

    const handleEdit = async (id) => {
        setSelectedFaqId(id);
        setShowEditForm(true);
        const { data } = await getFaqById.refetch();
        if (data) {
            const { id, question, answer } = data;
            setEditFaq({ ...editFaq, id, question, answer, role_type: "user" }); // Set the ID here
        }
    };

    const handleSaveEdit = async (updatedEditFaq) => {
        await editFaqMutation(updatedEditFaq);
    };

    const handleAddNew = async () => {
        await addFaqMutation(newFaq);
    };

    const handleDelete = async () => {
        await deleteFaqMutation(deleteId);
    };

    const openDeleteModal = (id) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };

    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "Some error occurred."
            ) : (
                <>
                    <div className="dash-first dash-common mb-4">
                        <div className="card-head p-0 text-end">
                            <button
                                className="text-sky-600 hover:text-sky-800 outline-1 outline-double rounded-full text-sm font-semibold px-4 py-2 d-inline-block"
                                onClick={() => setShowAddForm(true)}
                            >
                                + Add New
                            </button>
                        </div>
                        {showAddForm && (
                            <div className="dash-first dash-common box-border-3 mb-4" id="add-faq">
                                <div className="form-group mb-3">
                                    <label className="mb-2">Question</label>
                                    <input
                                        className="w-100 rounded-50 p-3"
                                        value={newFaq.question}
                                        onChange={(e) => setNewFaq({ ...newFaq, question: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="mb-2">Answer</label>
                                    <textarea
                                        className="w-100 textarea-round p-3"
                                        rows="6"
                                        value={newFaq.answer}
                                        onChange={(e) => setNewFaq({ ...newFaq, answer: e.target.value })}
                                    />
                                </div>
                                <div className="text-end mt-3 flex justify-end gap-3">
                                    <button
                                        className="text-stone-600 hover:text-stone-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                        onClick={() => setShowAddForm(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="text-white bg-red-600 hover:bg-red-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                        onClick={handleAddNew}
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        )}
                        {faqData.map((faq) => (
                            <div key={faq._id} className="card-body faq-list box-border-3 py-3 mt-4">
                                {selectedFaqId === faq._id && showEditForm ? (
                                    <div className="dash-first dash-common box-border-3 mb-4 w-100" id="edit-faq">
                                        <div className="form-group mb-3">
                                            <label className="mb-2">Question</label>
                                            <input
                                                className="w-100 rounded-50 p-3"
                                                value={editFaq.question}
                                                onChange={(e) => setEditFaq({ ...editFaq, question: e.target.value })}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="mb-2">Answer</label>
                                            <textarea
                                                className="w-100 textarea-round p-3"
                                                rows="6"
                                                value={editFaq.answer}
                                                onChange={(e) => setEditFaq({ ...editFaq, answer: e.target.value })}
                                            />
                                        </div>
                                        <div className="text-end mt-3 flex justify-end gap-3">
                                            <button
                                                className="text-stone-600 hover:text-stone-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                                onClick={() => setShowEditForm(false)}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="text-white bg-red-600 hover:bg-red-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                                onClick={() => {
                                                    const updatedEditFaq = { ...editFaq, id: faq._id };
                                                    setEditFaq(updatedEditFaq);
                                                    handleSaveEdit(updatedEditFaq);
                                                }}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <p className="mb-0 line-clamp">{faq.question}</p>
                                        <div className="d-flex">
                                            <button className="edit-icon me-2" onClick={() => handleEdit(faq._id)}>
                                                <MdModeEdit />
                                            </button>
                                            <button className="edit-icon" onClick={() => openDeleteModal(faq._id)}>
                                                <MdDelete />
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            )}
            <Modal show={showDeleteModal} onHide={closeDeleteModal} size="sm" centered>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3">
                            <p className="modalTitle text-center mb-2 fw-bold">Are you sure you want to delete this FAQ?</p>
                            <div className="btn-wrapper text-center">
                                <Button variant="secondary" className="btn btn-form btn-cancel" onClick={closeDeleteModal}>
                                    No
                                </Button>
                                <Button variant="primary" className="btn btn-form btn-add ms-3" onClick={handleDelete}>
                                    Yes
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default UserFaqs;
