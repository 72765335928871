import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/image/logo.svg";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useResetPasswordMutation } from "../../Services/api";
import { showToast } from "../../Services/tostify";
import { useDispatch } from "react-redux";

const IconCheck = require("../../assets/image/icon-check.png");

const ResetPassword = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const email = location.pathname.split("/")[2];
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: email,
        password: "",
        confirmPassword: "",
    });
    const [show, setShow] = useState(false);
    const showPassoword = () => setShow(!show);
    const [resetPassword, response] = useResetPasswordMutation();

    const handleResetPassword = async () => {
        if (formData.password !== formData.confirmPassword) {
            dispatch(showToast("Password Does not match", "FAIL_TOAST"));
        } else {
            await resetPassword(formData);
        }
    };
    useEffect(() => {
        if (!response.isLoading && response.isSuccess) {
            navigate("/");
            dispatch(showToast("Password Reset SuucessFully", "SUCCESS_TOAST"));
        } else if (response.isError && response.status === "rejected") {
            console.log(response.error);
            dispatch(showToast(response?.error?.data?.message, "FAIL_TOAST"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    return (
        <>
            <div className="flex-center flex-column">
                <div>
                    <img className="" alt="logo" src={Logo} />
                </div>
                <div className="card login-wrapper border-0 mt-5">
                    <div className="card-header border-0 bg-transparent text-center">
                        <h4 className="text-xl font-bold">Reset Password</h4>
                    </div>
                    <div className="card-body border-0 mt-3">
                        <form className="custom_form">
                            <div className="form-group">
                                <div class="relative">
                                    <input
                                        type={show ? "text" : "password"}
                                        class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-xl outline-1 outline-double outline-gray-400  dark:text-dark dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                                        id="pwd"
                                        name="password"
                                        placeholder=""
                                        onChange={(e) => {
                                            setFormData({ ...formData, password: e.target.value });
                                        }}
                                    />
                                    <label
                                        for="floating_outlined"
                                        class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                    >
                                        Password
                                    </label>
                                    <div className="pos-abs hide-show-pass" onClick={showPassoword}>
                                        {show ? <FaEye /> : <FaEyeSlash />}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div class="relative">
                                    <input
                                        type={show ? "text" : "password"}
                                        class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-xl outline-1 outline-double outline-gray-400  dark:text-dark dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                                        id="pwd"
                                        name="confirmPassword"
                                        placeholder=""
                                        onChange={(e) => {
                                            setFormData({ ...formData, confirmPassword: e.target.value });
                                        }}
                                    />
                                    <label
                                        for="floating_outlined"
                                        class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                    >
                                        Confirm Password
                                    </label>
                                    <div className="pos-abs hide-show-pass" onClick={showPassoword}>
                                        {show ? <FaEye /> : <FaEyeSlash />}
                                    </div>
                                </div>
                            </div>

                            <Link
                                onClick={handleResetPassword}
                                className="text-white bg-red-600 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-xl text-base px-5 py-3.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 block"
                            >
                                Send
                            </Link>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
