import React from 'react'
import { useState } from 'react';
import Spinnerr from "../../Components/Spinner/Spinner";
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import trash from './../../assets/image/rounded-remove.svg'
import moment from "moment";
import noimage from './../../assets/image/noimage.png'
import { useDeleteNewsMutation, useGetNewsListMutation } from '../../Services/api';
import Pagination from "../../Components/Pagination/Pagination";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { showToast } from "../../Services/tostify";
const News = ({isNotification}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [data, setData] = useState();
  const [page, setPage] = useState(1); // Initialize page state to start from 0
  const [totalPages, setTotalPages] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  const [myid, setId] = useState();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    limit: 4,
  });
  const [getNews, response] = useGetNewsListMutation();
  useEffect(() => {
    getNews(searchQuery);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, page]);


  const [deleteNews, response2] = useDeleteNewsMutation();
  const navigate = useNavigate()
  const deletenews = (id) => {
    setModalShow(true);
    setId(id)

  }


  useEffect(() => {
    if (!response.isLoading) {
      setLoading(false);
      setData(response?.data?.data);
      console.log(response?.data?.data, "News Data")
      // Update current page, total pages, and next page from response data
      setPage(response?.data?.data?.page);
      setTotalPages(response?.data?.data?.total_pages);
      if (response.data?.data?.notification_unread_count > 0) {
        isNotification(response.data?.data?.notification_unread_count);
    }
      // Assuming the API response has a field named `next_page` for the next page number



      setNextPage(response?.data?.data?.next_page);

      // As
    } else if (response.isError) {
      setLoading(false);
      setError(true);
    }
  }, [response]);
  const handledelete = (myid) => {
    deleteNews(myid)
  }

  useEffect(() => {
    if (!response2.isLoading && response2.isSuccess) {
      setModalShow(false)
      setLoading(false)
      getNews(searchQuery);
      dispatch(showToast(`News Deleted`, "SUCCESS_TOAST"));
    }
 else if (response2.isError && response2.status === "rejected") {
    // Handle error if the block API call fails
    setLoading(false);
    setError(true);
    dispatch(showToast("Some error occured", "FAIL_TOAST"));
}

  }, [response2])

  console.log(data, "data==========>")



  return (
    <>
      {loading ? (
        <Spinnerr />
      ) : error ? (
        "some error occured"
      ) : (
        <>
          <div className=' buttondiv flex justify-end mx-14'>
          <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className=' border-1 rounded-2xl bg-white p-3'>
          <div className='title text-center px-5 '>
            <span className=' font-bold'>Are You Sure Want To Delete This News</span>
          </div>

          <div className='bt flex gap-2  justify-center  my-2'>
            <Link onClick={() => { setModalShow(false) }} className=' border-[1px] border-black  rounded-xl text-base bg-white text-black  px-[40px] text-center py-1'>No</Link>
            <Link onClick={()=> handledelete(myid)} className=' border-[1px] border-black rounded-xl  text-base bg-black text-white   px-[40px] text-center py-1'>Yes</Link>


          </div>
        </div>


      </Modal>
            <Link
              className="text-sky-600 hover:text-sky-800  outline-1 outline-double rounded-full text-sm font-semibold px-4 py-2 d-inline-block"
              to="/news/addnews"
            >
              + Add more
            </Link>

          </div>
          <div className='flex flex-wrap'>



            {data?.coupon_list
              .map((item) => {
                return (
                  <div className=' border-[1px] rounded-xl mx-8 mt-4   bg-[#DFDFDF] p-3 text-white  flex-col max-w-lg '>
                    <Link onClick={() => deletenews(item._id)} className='  flex justify-end rounded-full '><img src={trash}></img> </Link>

                    <div className='flex justify-center py-1 px-28 '>
                      <img src={item.news_image} className=' w-48 h-32 rounded-lg'></img>
                    </div>

                    <div className=''>
                      <p className=' font-bold text-lg text-gray-500 '>{moment(item.createdAt).format("MM/DD/YYYY") || "-"}</p>
                      <p className=' text-black text-sm font-bold'>{item.title}</p>
                      <p className=' text-gray-500 text-sm '>{item.description.substring(0, 80)}</p>
                    </div>

                  </div>

                )
              })}
          </div>
          {data?.coupon_list.length > 0 ? (
            <Row className="mt-4 px-2">
              <Col md={5}>
                <div className="dataTables_info">
                  {`Showing ${Math.min((page - 1) * 4 + 1, data?.total)}` +
                    ` to ${Math.min(page * 4, data?.total) || 0} of ${data?.total || 0} entries`}
                </div>
              </Col>
              <Col md={7}>
                <Pagination
                  total={totalPages}
                  setPage={(newPage) => {
                    setSearchQuery((prevQuery) => ({
                      ...prevQuery,
                      page: newPage,
                    }));
                  }}
                  page={page} // Add 1 to page state to show correct page number
                  nextPage={nextPage} // Pass the next page number to the Pagination component
                />
              </Col>
            </Row>
          ) : (
            ""
          )}
        </>
      )}
    </>
  )
}

export default News