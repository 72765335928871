import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import Sort from "../../assets/image/sort.svg";
import Filter from "../../assets/image/filterr.svg";
import View from "../../assets/image/view.svg";
import Pagination from "../../Components/Pagination/Pagination";
import Cancel from "../../assets/image/cross.svg";
import Right from "../../assets/image/right.svg";
import { useEventListMutation } from "../../Services/api";

const EventRequests = ({ isNotification }) => {
    const totalPage = 2;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const [userId, setUserId] = useState(null);
    const [status, setStatus] = useState(null);
    const [page, setPage] = useState(1); // Initialize page state to start from 0
    const [totalPages, setTotalPages] = useState(1);
    const [nextPage, setNextPage] = useState(null);

    const [delet, setDelet] = useState(false);
    const deleteClose = () => setDelet(false);
    const deleteShow = () => setDelet(true);

    const [block, setBlock] = useState(false);
    const blockClose = () => setBlock(false);
    const blockShow = () => setBlock(true);

    const [event, setEvent] = useState(false);
    const eventClose = () => setEvent(false);
    const eventShow = () => setEvent(true);

    const [searchQuery, setSearchQuery] = useState({
        page: 1,
        limit: 10,
        search: "",
        sort: {
            createdAt: -1,
        },
    });
    const [eventData, response] = useEventListMutation();

    useEffect(() => {
        eventData(searchQuery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, page]);

    useEffect(() => {
        if (!response.isLoading) {
            setLoading(false);
            setData(response?.data?.data);
            // Update current page, total pages, and next page from response data
            setPage(response?.data?.data?.page);
            setTotalPages(response?.data?.data?.total_pages);
            if (response.data?.data?.notification_unread_count > 0) {
            
               isNotification(response.data?.data?.notification_unread_count);
            
            }
            // Assuming the API response has a field named `next_page` for the next page number
            setNextPage(response?.data?.data?.next_page);
        } else if (response.isError) {
            setLoading(false);
            setError(true);
        }
    }, [response]);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchQuery((prevQuery) => ({
            ...prevQuery,
            page: 0, // Reset page to 0 when searching
            search: value,
        }));
    };
    const handleSort = (sort) => {
        setSearchQuery({ ...searchQuery, sort, status: 6, page: 1 });
    };

    return (
        <>
            <div className="dash-first dash-common">
                <div className="common-table-wrapper m-0">
                    <Row className="mb-4">
                        <Col md={6}>
                            <div className="flex gap-3">
                                <label className="relative block">
                                    <span className="sr-only">Search</span>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                        <svg className="h-5 w-5 fill-slate-500" viewBox="0 0 20 20">
                                            <path
                                                fillRule="evenodd"
                                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </span>
                                    <input
                                        className="placeholder:text-gray-400 block bg-gray-200 w-full border border-slate-300 rounded-xl py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base"
                                        placeholder="Search"
                                        type="text"
                                        name="Search"
                                    />
                                </label>
                                <div className="flex items-center gap-3">
                                    <Dropdown>
                                        <Dropdown.Toggle className="bg-transparent p-0 border-0" id="dropdown-basic">
                                            <span className="">
                                                <img src={Sort} alt="sort" />
                                            </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="shadow border-0 rounded-xl">
                                            <Dropdown.Item className="text-sm font-medium">All</Dropdown.Item>
                                            <Dropdown.Item className="text-sm font-medium">Event Data</Dropdown.Item>
                                            <Dropdown.Item className="text-sm font-medium">Fantasy Data</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <span className="">
                                        <img src={Filter} alt="filter" />
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}></Col>
                    </Row>
                    <div className="table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr.No.</th>
                                    <th>Event Name</th>
                                    <th>Organizer</th>
                                    <th>Location</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>
                                        Event
                                        <br />
                                        Amount
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>01</td>
                                    <td>Manchester united vs Chelsea event</td>
                                    <td>Manchester united Club</td>
                                    <td>Gurugram, Haryana</td>
                                    <td>12-Dec-23</td>
                                    <td>05:00 AM</td>
                                    <td>₹200</td>
                                    <td className="action-tab flex justify-center gap-2">
                                        <Link className="inline-block" to="/event-details">
                                            <img src={View} alt="" />
                                        </Link>
                                        <Link className="inline-block" onClick={eventShow}>
                                            <img src={Cancel} alt="" />
                                        </Link>
                                        <Link className="inline-block">
                                            <img src={Right} alt="" />
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>02</td>
                                    <td>Manchester united vs Chelsea event</td>
                                    <td>Manchester united Club</td>
                                    <td>Gurugram, Haryana</td>
                                    <td>12-Dec-23</td>
                                    <td>05:00 AM</td>
                                    <td>₹200</td>
                                    <td className="action-tab flex justify-center gap-2">
                                        <Link className="inline-block" to="/event-details">
                                            <img src={View} alt="" />
                                        </Link>
                                        <Link className="inline-block" onClick={eventShow}>
                                            <img src={Cancel} alt="" />
                                        </Link>
                                        <Link className="inline-block">
                                            <img src={Right} alt="" />
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>03</td>
                                    <td>Manchester united vs Chelsea event</td>
                                    <td>Manchester united Club</td>
                                    <td>Gurugram, Haryana</td>
                                    <td>12-Dec-23</td>
                                    <td>05:00 AM</td>
                                    <td>₹200</td>
                                    <td className="action-tab flex justify-center gap-2">
                                        <Link className="inline-block" to="/event-details">
                                            <img src={View} alt="" />
                                        </Link>
                                        <Link className="inline-block" onClick={eventShow}>
                                            <img src={Cancel} alt="" />
                                        </Link>
                                        <Link className="inline-block">
                                            <img src={Right} alt="" />
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>04</td>
                                    <td>Manchester united vs Chelsea event</td>
                                    <td>Manchester united Club</td>
                                    <td>Gurugram, Haryana</td>
                                    <td>12-Dec-23</td>
                                    <td>05:00 AM</td>
                                    <td>₹200</td>
                                    <td className="action-tab flex justify-center gap-2">
                                        <Link className="inline-block" to="/event-details">
                                            <img src={View} alt="" />
                                        </Link>
                                        <Link className="inline-block" onClick={eventShow}>
                                            <img src={Cancel} alt="" />
                                        </Link>
                                        <Link className="inline-block">
                                            <img src={Right} alt="" />
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>05</td>
                                    <td>Manchester united vs Chelsea event</td>
                                    <td>Manchester united Club</td>
                                    <td>Gurugram, Haryana</td>
                                    <td>12-Dec-23</td>
                                    <td>05:00 AM</td>
                                    <td>₹200</td>
                                    <td className="action-tab flex justify-center gap-2">
                                        <Link className="inline-block" to="/event-details">
                                            <img src={View} alt="" />
                                        </Link>
                                        <Link className="inline-block" onClick={eventShow}>
                                            <img src={Cancel} alt="" />
                                        </Link>
                                        <Link className="inline-block">
                                            <img src={Right} alt="" />
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>06</td>
                                    <td>Manchester united vs Chelsea event</td>
                                    <td>Manchester united Club</td>
                                    <td>Gurugram, Haryana</td>
                                    <td>12-Dec-23</td>
                                    <td>05:00 AM</td>
                                    <td>₹200</td>
                                    <td className="action-tab flex justify-center gap-2">
                                        <Link className="inline-block" to="/event-details">
                                            <img src={View} alt="" />
                                        </Link>
                                        <Link className="inline-block" onClick={eventShow}>
                                            <img src={Cancel} alt="" />
                                        </Link>
                                        <Link className="inline-block">
                                            <img src={Right} alt="" />
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>07</td>
                                    <td>Manchester united vs Chelsea event</td>
                                    <td>Manchester united Club</td>
                                    <td>Gurugram, Haryana</td>
                                    <td>12-Dec-23</td>
                                    <td>05:00 AM</td>
                                    <td>₹200</td>
                                    <td className="action-tab flex justify-center gap-2">
                                        <Link className="inline-block" to="/event-details">
                                            <img src={View} alt="" />
                                        </Link>
                                        <Link className="inline-block" onClick={eventShow}>
                                            <img src={Cancel} alt="" />
                                        </Link>
                                        <Link className="inline-block">
                                            <img src={Right} alt="" />
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>08</td>
                                    <td>Manchester united vs Chelsea event</td>
                                    <td>Manchester united Club</td>
                                    <td>Gurugram, Haryana</td>
                                    <td>12-Dec-23</td>
                                    <td>05:00 AM</td>
                                    <td>₹200</td>
                                    <td className="action-tab flex justify-center gap-2">
                                        <Link className="inline-block" to="/event-details">
                                            <img src={View} alt="" />
                                        </Link>
                                        <Link className="inline-block" onClick={eventShow}>
                                            <img src={Cancel} alt="" />
                                        </Link>
                                        <Link className="inline-block">
                                            <img src={Right} alt="" />
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <Row className="mt-4 px-2">
                        <Col md={5}>
                            <div className="dataTables_info">Showing 1 to 10 of 10 entries</div>
                        </Col>
                        <Col md={7}>
                            <Pagination total={totalPage} setPage={setPage} page={page} />
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal dialogClassName="modal-custom-sm" centered show={delet} onHide={deleteClose}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3">
                            <p className="modalTitle text-center mb-2 fw-bold">
                                Are you sure you want to
                                <br />
                                delete this user?
                            </p>
                            <form className="custom_form">
                                <div className="btn-wrapper text-center">
                                    <Link className="btn btn-form btn-cancel" onClick={deleteClose}>
                                        No
                                    </Link>
                                    <Link className="btn btn-form btn-add" onClick={deleteClose}>
                                        Yes
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal dialogClassName="modal-custom-sm" centered show={block} onHide={blockClose}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3">
                            <p className="modalTitle text-center mb-2 fw-bold">
                                Are you sure you want to
                                <br />
                                block this user?
                            </p>
                            <form className="custom_form">
                                <div className="btn-wrapper text-center">
                                    <Link className="btn btn-form btn-cancel" onClick={blockClose}>
                                        No
                                    </Link>
                                    <Link className="btn btn-form btn-add" onClick={blockClose}>
                                        Yes
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="sm" centered show={event} onHide={eventClose}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body p-0">
                            <Form className="custom_form">
                                <div className="py-4 px-4">
                                    <p className="text-lg font-semibold text-center mb-3">Are you sure you want to reject this event?</p>

                                    <Form.Group className="mb-3 cf_label" controlId="Entry Fee">
                                        <Form.Control
                                            className="cf_input rounded-lg"
                                            as="textarea"
                                            rows={4}
                                            placeholder="Write your reason..."
                                            value=""
                                        />
                                    </Form.Group>

                                    <div className="btn-wrapper text-center gap-3">
                                        <Link className="btn btn-form btn-cancel rounded-xl w-50" onClick={eventClose}>
                                            No
                                        </Link>
                                        <Link className="btn btn-form btn-add rounded-xl w-50" onClick={eventClose}>
                                            Yes
                                        </Link>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EventRequests;
