import React, { useEffect, useState } from "react";
import { Row, Col, Dropdown, Modal, Form } from "react-bootstrap";
import { showToast } from "../../Services/tostify";
import { useEventListMutation } from "../../Services/api";
import { useDispatch } from "react-redux";
import Sort from "../../assets/image/sort-down-alt.svg";
import Filter from "../../assets/image/filter.svg";
import Dummy from "../../assets/image/user-circle.svg";
import View from "../../assets/image/view.svg";
import Block from "../../assets/image/block.svg";
import Spinnerr from "../../Components/Spinner/Spinner";
import moment from "moment";
import { MdStar } from "react-icons/md";
import { Link } from "react-router-dom";
import Pagination from "../../Components/Pagination/Pagination";

const ApprovedEvents = ({ apiTrigger, triggerApiCall, isNotification }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    //   const [orgId, setOrgId] = useState(null);
    const [error, setError] = useState(false);
    const [block, setBlock] = useState(false);
    //   const [status, setStatus] = useState(null);
    const [data, setData] = useState(null);
    const [searchQuery, setSearchQuery] = useState({
        page: 1,
        limit: 10,
        search: "",
        sort: {
            createdAt: -1,
        },
        status: 2,
    });

    const [page, setPage] = useState(1); // Initialize page state to start from 0
    const [totalPages, setTotalPages] = useState(1);
    const [nextPage, setNextPage] = useState(null);

    const blockClose = () => setBlock(false);
    const blockShow = () => {
        dispatch(showToast("This feature coming soon", "INFO_TOAST"));
    };
    //   const blockShow = () => setBlock(true);
    const [eventList, response] = useEventListMutation();

    useEffect(() => {
        if (!response.isLoading && response.isSuccess) {
            setLoading(false);
            setData(response?.data.data);
            setPage(response?.data?.data?.page);
            setTotalPages(response?.data?.data?.total_pages);
            setNextPage(response?.data?.data?.next_page);
            if (response.data?.data?.notification_unread_count > 0) {

                isNotification(response.data?.data?.notification_unread_count);

            }
        } else if (response.isError && response.status === "rejected") {
            setError(true);
            setLoading(false);
        }
    }, [response]);

    useEffect(() => {
        // Fetch data when the component mounts and whenever searchQuery changes
        eventList(searchQuery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, apiTrigger]);
    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "some error occured"
            ) : (
                <div className="common-table-wrapper m-0">
                    <Row className="mb-4">
                        <Col md={6}>
                            <div className="flex gap-3">
                                <label className="relative block">
                                    <span className="sr-only">Search</span>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                        <svg className="h-5 w-5 fill-slate-500" viewBox="0 0 20 20">
                                            <path
                                                fillRule="evenodd"
                                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </span>
                                    <input
                                        className="placeholder:text-gray-400 block bg-gray-200 w-full border border-slate-300 rounded-xl py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base"
                                        placeholder="Search"
                                        type="text"
                                        name="Search"
                                        value={searchQuery.search} // Bind the value of the input field to the search query
                                        onChange={(e) => setSearchQuery({ ...searchQuery, search: e.target.value, page: 1 })}
                                    />
                                </label>
                                <div className="flex items-center gap-3">
                                    <Dropdown>
                                        <Dropdown.Toggle className="bg-transparent p-0 border-0" id="dropdown-basic">
                                            <span>
                                                <img src={Sort} alt="sort" />
                                            </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="shadow border-0 rounded-xl">
                                            <Dropdown.Item onClick={() => setSearchQuery({ ...searchQuery, sort: { event_name: 1 }, page: 1 })}>
                                                Sort by Event Name (A-Z)
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSearchQuery({ ...searchQuery, sort: { event_name: -1 }, page: 1 })}>
                                                Sort by Event Name (Z-A)
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSearchQuery({ ...searchQuery, sort: { createdAt: 1 }, page: 1 })}>
                                                Sort by Date (Oldest first)
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSearchQuery({ ...searchQuery, sort: { createdAt: -1 }, page: 1 })}>
                                                Sort by Date (Newest first)
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {/* <span>
                    <img src={Filter} alt="filter" />
                  </span> */}
                                </div>
                            </div>
                        </Col>
                        <Col md={6}></Col>
                    </Row>
                    <div className="table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr.No.</th>
                                    <th>
                                        Event
                                        <br />
                                        Name
                                    </th>
                                    <th>Organizer</th>
                                    <th>Location</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>
                                        Event
                                        <br />
                                        Amount
                                    </th>
                                    <th>
                                        Total Revenue
                                        <br />
                                        Generated From it
                                    </th>
                                    <th>My Cut</th>
                                    <th>Tax</th>
                                    <th>
                                        Amount To
                                        <br />
                                        Distribute
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.event_list.length > 0 ? (
                                    data.event_list.map(
                                        (
                                            {
                                                _id,
                                                event_name,
                                                organizer_detail,
                                                location,
                                                createdAt,
                                                total_revenue,
                                                my_cut,
                                                price,
                                                total_tax,
                                                amount_to_distribute,
                                            },
                                            i,
                                        ) => (
                                            <tr key={i}>
                                                <td>{(page - 1) * 10 + i + 1}</td>
                                                <td>{event_name}</td>
                                                <td>{organizer_detail?.organizer_name}</td>
                                                <td>{location}</td>
                                                <td>{moment(createdAt).format("MM/DD/YYYY")}</td>
                                                <td>{moment(createdAt).format("HH : mm A")}</td>
                                                <td>₹ {price}</td>
                                                <td>₹{total_revenue.toFixed(2) || 0}</td>
                                                <td>₹{my_cut !== null ? my_cut.toFixed(2) : 0}</td>
                                                <td>₹{total_tax !== null ? total_tax.toFixed(2) : 0}</td>
                                                <td>₹{amount_to_distribute !== null ? amount_to_distribute.toFixed(2) : 0}</td>
                                                <td className="action-tab flex justify-center gap-2">
                                                    <Link className="inline-block" to={`/event-management/event-details/${_id}`}>
                                                        <img src={View} alt="" />
                                                    </Link>
                                                    {/* <Link
                              className="inline-block"
                              onClick={() => {
                                blockShow();
                                setOrgId(_id);
                              }}
                            >
                              <img src={Block} alt="" />
                            </Link> */}
                                                </td>
                                            </tr>
                                        ),
                                    )
                                ) : (
                                    <td className="text-center" colSpan={13}>
                                        No Records Founds
                                    </td>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {data?.event_list.length > 0 ? (
                        <Row className="mt-4 px-2">
                            <Col md={5}>
                                <div className="dataTables_info">
                                    {`Showing ${Math.min((page - 1) * 10 + 1, data?.total)}` +
                                        ` to ${Math.min(page * 10, data?.total) || 0} of ${data?.total || 0} entries`}
                                </div>
                            </Col>
                            <Col md={7}>
                                <Pagination
                                    total={totalPages}
                                    setPage={(newPage) => {
                                        setSearchQuery((prevQuery) => ({
                                            ...prevQuery,
                                            page: newPage,
                                        }));
                                    }}
                                    page={page} // Add 1 to page state to show correct page number
                                    nextPage={nextPage} // Pass the next page number to the Pagination component
                                />
                            </Col>
                        </Row>
                    ) : (
                        ""
                    )}
                </div>
            )}
            <Modal dialogClassName="modal-custom-sm" centered show={block} onHide={blockClose}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3">
                            <p className="modalTitle text-center mb-2 fw-bold">
                                Are you sure you want to
                                <br />
                                block this User?
                            </p>
                            <form className="custom_form">
                                <div className="btn-wrapper text-center">
                                    <Link className="btn btn-form btn-cancel" onClick={blockClose}>
                                        No
                                    </Link>
                                    <Link className="btn btn-form btn-add" onClick={blockClose}>
                                        Yes
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* <Modal size="sm" centered show={rejection} onHide={() => setRejection(false)}>
    <Modal.Body className="p-0">
      <div className="card logout-card common_box">
        <div className="card-body p-0">
          <Form className="custom_form">
            <div className="py-4 px-4">
              <p className="text-lg font-semibold text-center mb-3">Enter your reason for rejection</p>
              <Form.Group className="mb-3 cf_label" controlId="rejectionReason">
                <Form.Control
                  className="cf_input rounded-lg"
                  as="textarea"
                  rows={4}
                  placeholder="Write your reason..."
                  value={rejectionReason}
                  onChange={(e) => setRejectionReason(e.target.value)}
                />
              </Form.Group>
              <div className="btn-wrapper text-center gap-3">
                <Link className="btn btn-form btn-cancel rounded-xl w-50" onClick={() => setRejection(false)}>
                  Cancel
                </Link>
                <Link className="btn btn-form btn-add rounded-xl w-50" onClick={handleSubmitRejection}>
                  Submit
                </Link>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Modal.Body>
  </Modal> */}
        </>
    );
};

export default ApprovedEvents;
