import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

import ApproveOrganizerManagement from "./ApproveOrganizerManagement";
import PendingOrganizerManagement from "./PendingOrganizerManagement";
import { useOrganiserListMutation } from "../../Services/api";

const OrganizerManagement = ({ isNotification }) => {
    const [apiTrigger, setApiTrigger] = useState(0);

    const searchQuery = {
        search: "",
        sort: {
            createdAt: -1,
        },
        status: 5,
    };
    const [organizerList, response] = useOrganiserListMutation();
    useEffect(() => {
        organizerList(searchQuery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiTrigger]);

    // Function to trigger API call
    const triggerApiCall = () => {
        // Increment the trigger value to force useEffect to re-run
        setApiTrigger((prevTrigger) => prevTrigger + 1);
    };
    return (
        <>
            <Tab.Container id="left-tabs-example" defaultActiveKey="Existing">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <Nav variant="pills" className="nav-pills-tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="Existing">Existing</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Requests" className="relative">
                                    <span className="w-3 h-3 rounded-full bg-gray-50 text-stone-950 inline-flex justify-center items-center absolute top-0 end-0 m-1.5 fs-10 p-1">
                                        {response && response?.data?.data.total ? response?.data?.data?.total : 0}
                                    </span>
                                    Requests
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="col-md-6"></div>
                </div>
                <Tab.Content className="mt-4">
                    <Tab.Pane eventKey="Existing">
                        {/* Pass apiTrigger and triggerApiCall as props */}
                        <ApproveOrganizerManagement apiTrigger={apiTrigger} triggerApiCall={triggerApiCall} isNotification={isNotification} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Requests">
                        {/* Pass apiTrigger and triggerApiCall as props */}
                        <PendingOrganizerManagement apiTrigger={apiTrigger} triggerApiCall={triggerApiCall} isNotification={isNotification} />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </>
    );
};

export default OrganizerManagement;
