import React, { useEffect, useState } from "react";
import { Form, InputGroup, Row, Col, Modal, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import View from "../../assets/image/view.svg";
import Sort from "../../assets/image/sort-down-alt.svg";
import Dummy from "../../assets/image/user-circle.svg";
import Pagination from "../../Components/Pagination/Pagination";
import Img from "../../assets/image/background-img.jpg";
import Cancel from "../../assets/image/cross.svg";
import Right from "../../assets/image/right.svg";
import { useOrganiserApproveMutation, useOrganiserListMutation } from "../../Services/api";
import { showToast } from "../../Services/tostify";
import { useDispatch } from "react-redux";
import Spinnerr from "../../Components/Spinner/Spinner";

const PendingOrganizerManagement = ({ apiTrigger, triggerApiCall, isNotification }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [orgId, setOrgId] = useState(null);
    const [error, setError] = useState(false);
    const [block, setBlock] = useState(false);
    const [status, setStatus] = useState(null);
    const [rejectionReason, setRejectionReason] = useState("");
    const [percentage, setPercentage] = useState("");
    const [data, setData] = useState(null);

    const [rejection, setRejection] = useState(false);
    const [approve, setApprove] = useState(false);
    const [searchQuery, setSearchQuery] = useState({
        page: 1,
        limit: 10,
        search: "",
        sort: {
            createdAt: -1,
        },
        status: 5,
    });

    const [page, setPage] = useState(1); // Initialize page state to start from 0
    const [totalPages, setTotalPages] = useState(1);
    const [nextPage, setNextPage] = useState(null);

    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    const blockClose = () => setBlock(false);
    // const blockShow = () => setBlock(true);

    const [organizerList, response] = useOrganiserListMutation();
    const [organiserAprrove, response2] = useOrganiserApproveMutation();

    const rejectionShow = () => setRejection(true);
    // const rejectionClose = () => setRejection(false);

    const approveShow = () => setApprove(true);
    const approveClose = () => setApprove(false);

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchQuery((prevQuery) => ({
            ...prevQuery,
            page: 1, // Reset page to 0 when searching
            search: value,
        }));
    };
    const handleSort = (sort) => {
        setSearchQuery({ ...searchQuery, sort, status: 6, page: 1 });
    };

    const handleApprove = (organizerId) => {
        setOrgId(organizerId);
        approveShow();
    };
    const handleReject = (organizerId) => {
        setOrgId(organizerId);
        rejectionShow();
    };

    useEffect(() => {
        if (!response.isLoading && response.isSuccess) {
            setLoading(false);
            setData(response?.data.data);
            setPage(response?.data?.data?.page);
            setTotalPages(response?.data?.data?.total_pages);
            setNextPage(response?.data?.data?.next_page);
            if (response.data?.data?.notification_unread_count > 0) {
            
               isNotification(response.data?.data?.notification_unread_count);
            
            }
        } else if (response.isError && response.status === "rejected") {
            setError(true);
            setLoading(false);
        }
    }, [response]);

    useEffect(() => {
        organizerList(searchQuery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, apiTrigger]);

    const handleDeleteConfirmation = async () => {
        try {
            // Perform delete action
            setDeleteConfirmation(false); // Close the delete confirmation modal after confirmation
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    };

    const handleApproveOrganizer = async () => {
        const data = {
            organizer_id: orgId,
            percent: percentage,
            status: "6", // 6 for approve
            reason: "", // No reason for approval
            delete_photos: [],
        };
        await organiserAprrove(data);
        // Handle success or display error message
        await organizerList(searchQuery);
        setApprove(false);
        setPercentage("");
    };

    const handleSubmitRejection = async () => {
        const requestData = {
            organizer_id: orgId,
            percent: "10",
            status: "7", // 7 for reject
            reason: rejectionReason,
            delete_photos: [],
        };
        await organiserAprrove(requestData);
        // Handle success or display error message
        await organizerList(searchQuery);
        setRejection(false); // Close the rejection modal after submission
        setRejectionReason("");
    };

    useEffect(() => {
        if (!response2.isLoading && response2.isSuccess) {
            setRejectionReason("");
            setPercentage("");
            triggerApiCall();
            dispatch(showToast(`organiser request ${status !== 7 ? "approved" : "rejected"}`, "SUCCESS_TOAST"));
        } else if (response2.isError && response2.status === "rejected") {
            console.log(response2.error?.data?.message);
            dispatch(showToast(response2.error?.data?.message, "FAIL_TOAST"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response2]);
    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "some error occured"
            ) : (
                <div className="common-table-wrapper m-0">
                    <Row className="mb-4">
                        <Col md={6}>
                            <div className="flex gap-3">
                                <label className="relative block">
                                    <span className="sr-only">Search</span>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                        <svg className="h-5 w-5 fill-slate-500" viewBox="0 0 20 20">
                                            <path
                                                fillRule="evenodd"
                                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </span>
                                    <input
                                        className="placeholder:text-gray-400 block bg-gray-200 w-full border border-slate-300 rounded-xl py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base"
                                        placeholder="Search"
                                        type="text"
                                        name="Search"
                                        value={searchQuery.search}
                                        onChange={handleSearch}
                                    />
                                </label>
                                <div className="flex items-center gap-3">
                                    <Dropdown>
                                        <Dropdown.Toggle className="bg-transparent p-0 border-0" id="dropdown-basic">
                                            <span>
                                                <img src={Sort} alt="sort" />
                                            </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="shadow border-0 rounded-xl">
                                            <Dropdown.Item onClick={() => handleSort({ organizer_name: 1 })}>
                                                Sort by Organizer Name (A-Z)
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleSort({ organizer_name: -1 })}>
                                                Sort by Organizer Name (Z-A)
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleSort({ createdAt: 1 })}>Sort by Date (Oldest first)</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleSort({ createdAt: -1 })}>Sort by Date (Newest first)</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}></Col>
                    </Row>
                    <div className="table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr.No.</th>
                                    <th>Organizer Name</th>
                                    <th>Business Email</th>
                                    <th>Location</th>
                                    <th>Photos</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.organizer_list.length > 0 ? (
                                    data.organizer_list.map(
                                        (
                                            {
                                                _id,
                                                organizer_name,
                                                image,
                                                createdAt,
                                                business_email,
                                                location,
                                                reviews,
                                                events_organized,
                                                total_revenue,
                                                my_cut,
                                                total_tax,
                                                amount_to_distribute,
                                                business_images,
                                            },
                                            i,
                                        ) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>
                                                    <span className="flex gap-2 items-center">
                                                        <img src={image || Dummy} width="15" height="15" className="rounded-full" alt="Dummy" />
                                                        {organizer_name}
                                                    </span>
                                                </td>
                                                <td>{business_email}</td>
                                                <td>{location}</td>
                                                <td>
                                                    <span className="inline-flex gap-1 items-center justify-center h-6">
                                                        {business_images.map(({ image, _id }, i) => (
                                                            <img
                                                                src={image || Img}
                                                                key={i}
                                                                alt={_id}
                                                                width="23"
                                                                height="23"
                                                                className="rounded-lg inline-block h-100"
                                                            />
                                                        ))}
                                                    </span>
                                                </td>
                                                <td className="action-tab flex justify-center gap-2">
                                                    <Link className="inline-block" to={`/organizer-management/view-request/${_id}`}>
                                                        <img src={View} alt="" />
                                                    </Link>
                                                    <Link
                                                        className="inline-block"
                                                        onClick={() => {
                                                            handleReject(_id);
                                                            setStatus(7);
                                                        }}
                                                    >
                                                        <img src={Cancel} alt="" />
                                                    </Link>
                                                    <Link
                                                        className="inline-block"
                                                        onClick={() => {
                                                            handleApprove(_id);
                                                            setStatus(6);
                                                        }}
                                                    >
                                                        <img src={Right} alt="" />
                                                    </Link>
                                                </td>
                                            </tr>
                                        ),
                                    )
                                ) : (
                                    <td className="text-center" colSpan={13}>
                                        No Records Founds
                                    </td>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {data?.organizer_list.length > 0 ? (
                        <Row className="mt-4 px-2">
                            <Col md={5}>
                                <div className="dataTables_info">
                                    {`Showing ${Math.min((page - 1) * 10 + 1, data?.total)}` +
                                        ` to ${Math.min(page * 10, data?.total) || 0} of ${data?.total || 0} entries`}
                                </div>
                            </Col>
                            <Col md={7}>
                                <Pagination
                                    total={totalPages}
                                    setPage={(newPage) => {
                                        setSearchQuery((prevQuery) => ({
                                            ...prevQuery,
                                            page: newPage,
                                        }));
                                    }}
                                    page={page} // Add 1 to page state to show correct page number
                                    nextPage={nextPage} // Pass the next page number to the Pagination component
                                />
                            </Col>
                        </Row>
                    ) : (
                        ""
                    )}
                </div>
            )}

            {/* <Modal dialogClassName="modal-custom-sm" centered show={delet} onHide={deleteClose}>
        <Modal.Body className="p-0">
          <div className="card logout-card common_box">
            <div className="card-body common_box_body py-4 px-3">
              <p className="modalTitle text-center mb-2 fw-bold">
                Are you sure you want to
                <br />
                delete this User?
              </p>
              <form className="custom_form">
                <div className="btn-wrapper text-center">
                  <Link className="btn btn-form btn-cancel" onClick={deleteClose}>
                    No
                  </Link>
                  <Link className="btn btn-form btn-add" onClick={deleteClose}>
                    Yes
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

            <Modal dialogClassName="modal-custom-sm" centered show={block} onHide={blockClose}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3">
                            <p className="modalTitle text-center mb-2 fw-bold">
                                Are you sure you want to
                                <br />
                                block this User?
                            </p>
                            <form className="custom_form">
                                <div className="btn-wrapper text-center">
                                    <Link className="btn btn-form btn-cancel" onClick={blockClose}>
                                        No
                                    </Link>
                                    <Link className="btn btn-form btn-add" onClick={blockClose}>
                                        Yes
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="sm" centered show={rejection} onHide={() => setRejection(false)}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body p-0">
                            <Form className="custom_form">
                                <div className="py-4 px-4">
                                    <p className="text-lg font-semibold text-center mb-3">Enter your reason for rejection</p>
                                    <Form.Group className="mb-3 cf_label" controlId="rejectionReason">
                                        <Form.Control
                                            className="cf_input rounded-lg"
                                            as="textarea"
                                            rows={4}
                                            placeholder="Write your reason..."
                                            value={rejectionReason}
                                            onChange={(e) => setRejectionReason(e.target.value)}
                                        />
                                    </Form.Group>
                                    <div className="btn-wrapper text-center gap-3">
                                        <Link className="btn btn-form btn-cancel rounded-xl w-50" onClick={() => setRejection(false)}>
                                            Cancel
                                        </Link>
                                        <Link className="btn btn-form btn-add rounded-xl w-50" onClick={handleSubmitRejection}>
                                            Submit
                                        </Link>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="sm" centered show={approve} onHide={approveClose}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body p-0">
                            <Form className="custom_form">
                                <div className="py-4 px-4">
                                    <p className="text-lg font-semibold text-center mb-3">
                                        Enter the percentage that will be deducted from organizer's earnings
                                    </p>

                                    <Form.Group className="mb-3 cf_label" controlId="Entry Fee">
                                        <InputGroup>
                                            <Form.Control
                                                className="cf_input rounded-lg"
                                                type="number"
                                                placeholder="Percentage"
                                                style={{ borderRight: 0 }}
                                                value={percentage}
                                                onChange={(e) => setPercentage(e.target.value)}
                                            />
                                            <InputGroup.Text
                                                style={{
                                                    background: "none",
                                                    border: "1px solid #C1C1C1",
                                                    padding: "0px 16px 0 0",
                                                    borderRadius: "0 15px 15px 0",
                                                }}
                                            >
                                                <span style={{ marginLeft: "5px", fontSize: "14px", lineHeight: "21px", color: "#000" }}>%</span>
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                    <div className="btn-wrapper text-center gap-3">
                                        <Link className="btn btn-form btn-cancel rounded-xl w-50" onClick={approveClose}>
                                            Cancel
                                        </Link>
                                        <Link className="btn btn-form btn-add rounded-xl w-50" onClick={handleApproveOrganizer}>
                                            Submit
                                        </Link>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PendingOrganizerManagement;
