import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Upload from "../../assets/image/awesome-image.svg";
import axios from "axios";
import { showToast } from "../../Services/tostify";
import { useDispatch } from "react-redux";
import moment from "moment";

const DiscountModal = ({ show, handleClose, id, couponData }) => {
  //   console.log(id, couponData);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    coupon_code: "",
    description: "",
    coupon_image: "",
    percentage: "",
    valid_from: "",
    valid_to: "",
    upto: "",
  });
  const [imagePreview, setImagePreview] = useState("");

  useEffect(() => {
    if (couponData) {
      setFormData({
        coupon_code: couponData.coupon_code || "",
        description: couponData.description || "",
        coupon_image: couponData.coupon_image || "", // Handle coupon image as URL
        percentage: couponData.percentage || "",
        upto: couponData.upto || "",
        valid_from: couponData.valid_from ? moment(couponData.valid_from * 1000).format("YYYY-MM-DD") : "",
        // valid_from: couponData.valid_from ? moment(couponData.valid_from *1000).format("YYYY-MM-DD") : "",
        valid_to: couponData.valid_to ? moment(couponData.valid_to * 1000).format("YYYY-MM-DD") : "",
      });
      setImagePreview(couponData.coupon_image || "");
    }
  }, [couponData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, coupon_image: e.target.files[0] });
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Convert date fields to timestamps
    const formDataToSend = new FormData();
    for (let key in formData) {
      if (key === "valid_from" || key === "valid_to") {
        formDataToSend.append(key, moment(formData[key]).unix());
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }
    formDataToSend.append("coupon_id", id);

    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };

    try {
      // const apiUrl =
      //   couponData && id ? "http://quytech.net:9000/admin/discount/v1/edit_coupon" : "http://quytech.net:9000/admin/discount/v1/add_coupon";
      const apiUrl =
        couponData && id ? "https://api.whathefootball.com/admin/discount/v1/edit_coupon" : "https://api.whathefootball.com/admin/discount/v1/add_coupon";
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(apiUrl, formDataToSend, { headers });

      dispatch(showToast(couponData && id ? "Coupon Edited Successfully" : "Coupon Added Successfully", "SUCCESS_TOAST"));
      setFormData({
        coupon_code: "",
        description: "",
        coupon_image: "",
        percentage: "",
        valid_from: "",
        valid_to: "",
      });
      setImagePreview("");
      handleClose();
    } catch (error) {
      console.error("Error:", error);
      dispatch(showToast("Some Error Occurred", "FAIL_TOAST"));
    }
  };

  return (
    <>
      <Modal
        size="sm"
        centered
        show={show}
        onHide={() => {
          handleClose();
          setFormData({
            coupon_code: "",
            description: "",
            coupon_image: "",
            percentage: "",
            valid_from: "",
            valid_to: "",
          });
          setImagePreview("");
        }}
      >
        <Modal.Body className="p-0">
          <div className="card logout-card common_box">
            <div className="card-body p-0">
              <Form className="custom_form" onSubmit={handleSubmit}>
                <div className="py-4 px-4">
                  <p className="modalMainTitle text-center mb-3">Coupon Details</p>
                  <Form.Group className="mb-3" controlId="couponImage">
                    <label
                      htmlFor="offer"
                      className="position-relative d-flex justify-content-center align-items-center flex-column gap-2 w-100 h-24 text-sm font-medium text-gray-500 outline-1 outline-dashed outline-gray-500 rounded-lg"
                    >
                      {imagePreview ? (
                        <img src={imagePreview} alt="upload" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                      ) : (
                        <>
                          <img src={Upload} alt="upload" />+ Add image
                        </>
                      )}
                      <input
                        type="file"
                        id="offer"
                        className="position-absolute top-0 bottom-0 left-0 right-0 opacity-0 cursor-pointer rounded-lg"
                        onChange={handleFileChange}
                      />
                    </label>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="couponCode">
                    <Form.Control
                      className="cf_input rounded-lg"
                      type="text"
                      placeholder="Coupon Code"
                      name="coupon_code"
                      value={formData.coupon_code}
                      onChange={(e) => handleChange(e)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="couponDescription">
                    <Form.Control
                      className="cf_input rounded-lg"
                      as="textarea"
                      rows={3}
                      placeholder="Description"
                      name="description"
                      value={formData.description}
                      onChange={(e) => handleChange(e)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="couponPercentage">
                    <Form.Control
                      className="cf_input rounded-lg"
                      type="text"
                      placeholder="Percentage"
                      name="percentage"
                      value={formData.percentage}
                      onChange={(e) => handleChange(e)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="couponUpto">
                    <Form.Control
                      className="cf_input rounded-lg"
                      type="text"
                      placeholder="upto"
                      name="upto"
                      value={formData.upto}
                      onChange={(e) => handleChange(e)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="couponValidFrom">
                    <Form.Control
                      className="cf_input rounded-lg"
                      type="date"
                      placeholder="Valid From"
                      name="valid_from"
                      value={formData.valid_from}
                      onChange={handleChange}
                      min={moment().format("YYYY-MM-DD")} // Set the minimum date to today
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="couponValidTo">
                    <Form.Control
                      className="cf_input rounded-lg"
                      type="date"
                      placeholder="Valid to"
                      name="valid_to"
                      value={formData.valid_to}
                      onChange={handleChange}
                      min={moment().format("YYYY-MM-DD")} // Set the minimum date to today
                    />
                  </Form.Group>

                  <div className="btn-wrapper text-center gap-3">
                    <Link
                      className="btn btn-form btn-cancel rounded-lg w-50"
                      onClick={() => {
                        handleClose();
                        setFormData({
                          coupon_code: "",
                          description: "",
                          coupon_image: "",
                          percentage: "",
                          valid_from: "",
                          valid_to: "",
                          upto: "",
                        });
                        setImagePreview("");
                      }}
                    >
                      Cancel
                    </Link>

                    <button type="submit" className="btn btn-form btn-add rounded-lg w-50">
                      {couponData ? "Save" : "Add"}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DiscountModal;
