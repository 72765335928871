import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useLocation } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import Dummy from "../../assets/image/demo-image.png";
import { useEventDetailsbyIdMutation } from "../../Services/api";
import Spinnerr from "../../Components/Spinner/Spinner";
import moment from "moment";

const EventDetail = ({ isNotification }) => {
    const location = useLocation();
    const event_id = location.pathname.split("/")[5];
    const path = location.pathname.split("/event-detail")[0];
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);

    const [eventDetail, response] = useEventDetailsbyIdMutation();

    useEffect(() => {
        const body = {
            event_id: event_id,
        };
        eventDetail(body);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!response.isLoading) {
            setLoading(false);
            setData(response?.data?.data);
            if (response.data?.data?.notification_unread_count > 0) {
            
               isNotification(response.data?.data?.notification_unread_count);
            
            }
        } else if (response.isError) {
            setLoading(false);
            setError(true);
        }
    }, [response]);
    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "some error occured"
            ) : (
                <>
                    <Row>
                        <Col xl={2}>
                            <Link
                                className="bg-gray-800 hover:bg-gray-600 text-white outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 d-inline-block"
                                to={path}
                            >
                                Back
                            </Link>
                        </Col>
                    </Row>
                    {data?.event_detail === null ? (
                        <h1 className="my-5 mx-auto w-100 text-center">No Event data</h1>
                    ) : (
                        <>
                            <Row className="mt-4">
                                <Col xl={4}>
                                    <Carousel controls={false} className="h-56">
                                        {data?.event_detail?.event_images.map(({ image, event_id }, i) => (
                                            <Carousel.Item className="h-100 rounded-xl overflow-auto" key={i}>
                                                <img className="d-block w-100 h-100 object-cover" src={image || Dummy} alt={event_id} />
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </Col>
                                <Col xl={7}>
                                    <span className="block text-xs text-gray-500">{data?.event_detail?.location}</span>
                                    <div className="flex items-center justify-between mt-2">
                                        <h6 className="text-lg font-bold">{data?.event_detail?.event_name}</h6>
                                        <span className="text-base font-semibold">₹{data?.event_detail?.price}</span>
                                    </div>
                                    <span className="block text-xs text-gray-500">
                                        {moment(data?.event_detail?.date_time).format("DD MMM YYY, HH : mm A")}
                                    </span>
                                    <p className="text-xs text-gray-600 font-medium mt-3 lh-base">{data?.event_detail?.description}</p>
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default EventDetail;
