import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAddContactUsMutation, useGetContactUsQuery } from "../../Services/api";
import { showToast } from "../../Services/tostify";
import { useDispatch } from "react-redux";
import Spinnerr from "../../Components/Spinner/Spinner";
const ContactUs = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  const [newData, setNewData] = useState({
    email: "",
    phone: "",
  });
  // const [isEditing, setIsEditing] = useState(false);

  const response = useGetContactUsQuery(null, { refetchOnMountOrArgChange: true });
  const [addAboutUs, response2] = useAddContactUsMutation();

  useEffect(() => {
    if (!response.isLoading) {
      setLoading(false);
      setData(response?.data?.data);
      // Initialize newData with the existing terms and conditions
      setNewData({ email: response?.data?.data?.email || "", phone: response?.data?.data?.phone || "" });
    } else if (response.isError) {
      setLoading(false);
      setError(true);
    }
  }, [response]);

  useEffect(() => {
    if (!response2.isLoading && response2.isSuccess) {
      setLoading(false);
      response.refetch();
      // setIsEditing(false); // Hide the textarea after saving
      dispatch(showToast("Contact Us Updated", "SUCCESS_TOAST"));
    } else if (response2.isError || response2.status === "rejected") {
      setLoading(false);
      setError(true);
      dispatch(showToast(response2?.error?.data?.message, "FAIL_TOAST"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response2]);

  const handleSave = () => {
    addAboutUs(newData);
  };

  return (
    <>
      {loading ? (
        <Spinnerr />
      ) : error ? (
        "some error occured"
      ) : (
        <>
          <div className="dash-first dash-common row col-md-6 px-0">
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <div className="relative pos-r">
                  <input
                    type="email"
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-xl outline-1 outline-double outline-gray-400  dark:text-black dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                    id="email"
                    value={newData.email}
                    onChange={(e) => setNewData({ ...newData, email: e.target.value })}
                    placeholder="help@gmail.com"
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Helpline email
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <div className="relative pos-r">
                  <input
                    type="text"
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-xl outline-1 outline-double outline-gray-400  dark:text-black dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                    id="number"
                    value={newData.phone}
                    onChange={(e) => setNewData({ ...newData, phone: e.target.value })}
                    placeholder="1234567890"
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Helpline number
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-12 text-end">
              <Link
                className="text-white bg-red-600 hover:bg-red-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                onClick={handleSave}
              >
                Save
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ContactUs;
