import React, { useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import OrganiserPrivacyPolicy from "./OrganiserPrivacyPolicy";
import UserPrivacyPolicy from "./UserPrivacyPolicy";

const PrivacyPolicy = () => {
  const [apiTrigger, setApiTrigger] = useState(0);

  const triggerApiCall = () => {
    // Increment the trigger value to force useEffect to re-run
    setApiTrigger((prevTrigger) => prevTrigger + 1);
  };
  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="organizer">
        <div className="row align-items-center">
          <div className="col-md-6">
            <Nav variant="pills" className="nav-pills-tabs">
              <Nav.Item>
                <Nav.Link eventKey="organizer" onClick={triggerApiCall}>
                  Organizer
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="user" className="relative" onClick={triggerApiCall}>
                  User
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <Tab.Content className="mt-4">
          <Tab.Pane eventKey="organizer">
            {/* Pass apiTrigger and triggerApiCall as props */}
            <OrganiserPrivacyPolicy apiTrigger={apiTrigger} triggerApiCall={triggerApiCall} />
          </Tab.Pane>
          <Tab.Pane eventKey="user">
            {/* Pass apiTrigger and triggerApiCall as props */}
            <UserPrivacyPolicy apiTrigger={apiTrigger} triggerApiCall={triggerApiCall} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default PrivacyPolicy;
