import React, { useEffect, useState } from "react";
import { useEventListMutation } from "../../Services/api";
import { Nav, Tab } from "react-bootstrap";
import ApprovedEvents from "./ApprovedEvents";
import PendingEvents from "./PendingEvents";

const EventManagement = ({ isNotification }) => {
    const [apiTrigger, setApiTrigger] = useState(0);

    const searchQuery = {
        page: 1,
        limit: 10,
        search: "",
        sort: {
            //   event_name: 1, // send 1:=> for assending, send -1:=> for desending
            createdAt: -1, // send 1:=> for oldest to newest, send -1 :=> for newest to oldest
        },
        status: "1", // send 1 for get pending request, And send 2 for getting approved list
        organizer_id: "",
    };
    const [eventList, response] = useEventListMutation();
    useEffect(() => {
        eventList(searchQuery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiTrigger]);

    // Function to trigger API call
    const triggerApiCall = () => {
        // Increment the trigger value to force useEffect to re-run
        setApiTrigger((prevTrigger) => prevTrigger + 1);
    };
    return (
        <>
            <Tab.Container id="left-tabs-example" defaultActiveKey="Existing">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <Nav variant="pills" className="nav-pills-tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="Existing">Existing</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Requests" className="relative">
                                    <span className="w-3 h-3 rounded-full bg-gray-50 text-stone-950 inline-flex justify-center items-center absolute top-0 end-0 m-1.5 fs-10 p-1">
                                        {response && response?.data?.data.total ? response?.data?.data?.total : 0}
                                    </span>
                                    Requests
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="col-md-6"></div>
                </div>
                <Tab.Content className="mt-4">
                    <Tab.Pane eventKey="Existing">
                        {/* Pass apiTrigger and triggerApiCall as props */}
                        <ApprovedEvents apiTrigger={apiTrigger} triggerApiCall={triggerApiCall} isNotification={isNotification} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Requests">
                        {/* Pass apiTrigger and triggerApiCall as props */}
                        <PendingEvents apiTrigger={apiTrigger} triggerApiCall={triggerApiCall} isNotification={isNotification} />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </>
    );
};

export default EventManagement;
