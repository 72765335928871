import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import Dummy from "../../assets/image/user-circle.svg";
import { useAddHelpAndSupportAnswerMutation, useGetHelpAndSupportbyIdQuery } from "../../Services/api";
import Spinnerr from "../../Components/Spinner/Spinner";
import { showToast } from "../../Services/tostify";

const ViewRequestSupport = ({ isNotification }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const id = location.pathname.split("/")[3];
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const [answer, setAnswer] = useState({
        reply: "",
    });

    const response = useGetHelpAndSupportbyIdQuery(id, { refetchOnMountOrArgChange: true });
    const [replyHelp, response2] = useAddHelpAndSupportAnswerMutation();
    useEffect(() => {
        if (!response.isLoading) {
            setLoading(false);
            setData(response?.data?.data?.help_and_support);
            if (response.data?.data?.notification_unread_count > 0) {
            
               isNotification(response.data?.data?.notification_unread_count);
            
            }
        } else if (response.isError) {
            setLoading(false);
            setError(true);
        }
    }, [response]);

    const handleAnswer = () => {
        const body = {
            support_id: id,
            ...answer,
        };
        replyHelp(body);
    };

    useEffect(() => {
        if (!response2.isLoading && response2.isSuccess) {
            setLoading(false); // Assuming the page should stop loading after the block API call
            // userData(searchQuery);
            response.refetch();
            setAnswer({ reply: "" });
            dispatch(showToast(`Help Replied Submitted`, "SUCCESS_TOAST"));
        } else if (response2.isError && response2.status === "rejected") {
            // Handle error if the block API call fails
            setLoading(false);
            setError(true);
            dispatch(showToast("Some error occured", "FAIL_TOAST"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response2]);
    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "some error occured"
            ) : (
                <Row>
                    <Col xl={2}>
                        <Link
                            className="bg-gray-800 hover:bg-gray-600 text-white outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 d-inline-block"
                            to={"/support-helpdesk"}
                        >
                            Back
                        </Link>
                    </Col>

                    <Col xl={8}>
                        <div className="text-center">
                            <div className="inline-flex flex-col justify-center items-center">
                                <img src={Dummy} alt="User" className="rounded-full" width="48" height="48" />
                                <span className="text-2xl font-bold mt-2 mb-0">{data?.user_data?.first_name}</span>
                                <span className="text-base opacity-50 font-semibold">{data?.user_data?.email}</span>
                            </div>
                        </div>
                        <div className="col-md-8 mx-auto mt-4">
                            <div className="bg-gray-200 rounded-xl px-2 py-3 text-center">
                                <p className="mb-0 text-sm font-medium">{data?.description}</p>
                            </div>
                            {data?.is_reply !== true ? (
                                <>
                                    <Form.Group className="my-3 cf_label" controlId="Reply">
                                        <Form.Control
                                            className="cf_input rounded-lg"
                                            as="textarea"
                                            rows={6}
                                            placeholder="Add your reply...."
                                            value={answer.answer}
                                            onChange={(e) => {
                                                setAnswer({ ...answer, reply: e.target.value });
                                            }}
                                        />
                                    </Form.Group>
                                    <div className="text-center">
                                        <Link
                                            className="text-white bg-red-600 hover:bg-red-800 outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                            onClick={handleAnswer}
                                        >
                                            Submit
                                        </Link>
                                    </div>
                                </>
                            ) : (
                                <div className="bg-gray-200 rounded-xl px-2 py-3 text-center my-4">
                                    <p className="mb-0 text-sm font-medium">
                                        <b>Reply</b> : {data?.reply}
                                    </p>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default ViewRequestSupport;
