import React, { useState } from 'react'
import UPLOAD_ICON from './../../assets/image/noimage.png'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { showToast } from "../../Services/tostify";
import { useDispatch } from "react-redux";
import { baseQuery } from '../../Services/api';
function AddNews({ isNotification }) {
  const navigate = useNavigate()
  const [title, settitle] = useState()
  const [description, setdescription] = useState()
  const [news_image, setnews_image] = useState()
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState("");
  const handleFileChange = (e) => {
    setnews_image(e.target.files[0])
    setImagePreview(URL.createObjectURL(e.target.files[0]));

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("description", description);
    formdata.append("news_image", news_image,
      news_image.name);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
    try {
      const apiUrl =
        "http://quytech.net:9000/admin/news/v2/create_news"
      // const apiUrl =
      // https://backend.whathefootball.com/admin/news/v2/create_news"

      const response = await axios.post(apiUrl, formdata, { headers })


      dispatch(showToast(`News Added`, "SUCCESS_TOAST"));
      navigate("/news")


    } catch (error) {
      console.error("Error:", error);
      dispatch(showToast("Some Error Occurred", "FAIL_TOAST"));
    }
  };



  return (


    <div className='AddNews mx-8  bg-gray-200 px-5  py-5 rounded-2xl min-w-min'>

      <div className='imagebox flex justify-around flex-wrap'>
        <div className=" flex flex-col flex-wrap px-40 py-4  rounded-lg border-dashed border-2 border-gray-300 h-52  mb-1 relative justify-center" style={{ alignContent: "center" }}>
          <img src={UPLOAD_ICON} alt="upload icon" className=' w-32 h-24' />
          <p className=' font-bold text-center'>+Add Image</p>
          <input
          
            type="file"
            className="opacity-0 w-80 h-28 absolute right-0 top-0 cursor-pointer"
            onChange={handleFileChange}
          />
        </div>

        <div className="   flex flex-col flex-wrap px-40 py-4 rounded-lg border-2 border-gray-300  mb-1 h-52  relative justify-center" style={{ alignContent: "center" }}>
          <img src={imagePreview === "" ? UPLOAD_ICON : imagePreview} alt="upload icon"className=' w-32 h-24 ' />
        </div>
      </div>

      <div className='mt-3'>
        <p className='pl-5 text-gray-500 mb-1'>Title </p>
        <div className=' rounded-full   border-2 w-full border-gray-300'>
          <input className="border-0 px-4 rounded-full py-3 w-full" type='text' placeholder='Title'
            maxLength="100"
            onChange={(e) => settitle(e.target.value)}
          ></input>
        </div>

        <p className='pl-5 mt-4 text-gray-500 mb-1'>Description </p>
        <div className=' rounded-full    w-full border-gray-300'>
          <textarea className="border-0 px-4 py-2 rounded-2xl mr-7 w-full h-40" placeholder='Description'
            maxlength="500"
            onChange={(e) => setdescription(e.target.value)}
          ></textarea>
        </div>
      </div>
      <div className=' flex justify-end mt-3'>
        <div className='button flex gap-3'>
          <Link to="/news" className=' border-[1px] border-black  rounded-full text-base bg-white text-black  px-[32px] text-center py-1'>Cancel</Link>
          <Link className=' border-[1px] border-black rounded-full  text-base bg-black text-white   px-[35px] text-center py-1'
            onClick={handleSubmit}
          >Send</Link>
        </div>
      </div>
    </div>
  )
}

export default AddNews