import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Sort from "../../assets/image/sort.svg";
import Sort from "../../assets/image/sort-down-alt.svg";
import Filter from "../../assets/image/filterr.svg";
import Dummy from "../../assets/image/user-circle.svg";
import View from "../../assets/image/view.svg";
import Pagination from "../../Components/Pagination/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { usePaymentListMutation } from "../../Services/api";
import Spinnerr from "../../Components/Spinner/Spinner";

const PaymentManagement = ({ isNotification }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const [page, setPage] = useState(1); // Initialize page state to start from 0
    const [totalPages, setTotalPages] = useState(1);
    const [nextPage, setNextPage] = useState(null);
    const [searchQuery, setSearchQuery] = useState({
        page: 1,
        limit: 10,
        search: "",
        sort: {
            createdAt: -1, // send 1:=> for oldest to newest, send -1 :=> for newest to oldest
        },
    });

    const [paymentList, response] = usePaymentListMutation();
    const handleSort = (sort) => {
        setSearchQuery({ ...searchQuery, sort });
    };
    const handleSearch = (e) => {
        setSearchQuery({ ...searchQuery, search: e.target.value, page: 1 });
    };
    useEffect(() => {
        if (!response.isLoading && response.isSuccess) {
            setLoading(false);
            setData(response?.data.data);
            setPage(response?.data?.data?.page);
            setTotalPages(response?.data?.data?.total_pages);
            setNextPage(response?.data?.data?.next_page);
            if (response.data?.data?.notification_unread_count > 0) {
            
               isNotification(response.data?.data?.notification_unread_count);
            
            }
        } else if (response.isError && response.status === "rejected") {
            setError(true);
            setLoading(false);
        }
    }, [response]);

    useEffect(() => {
        paymentList(searchQuery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);
    const [delet, setDelet] = useState(false);
    const deleteClose = () => setDelet(false);
    const deleteShow = () => setDelet(true);

    const [block, setBlock] = useState(false);
    const blockClose = () => setBlock(false);
    const blockShow = () => setBlock(true);

    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "some error occured"
            ) : (
                <>
                    <Row>
                        <Col xl={4} sm={6} className="mb-2">
                            <div className="bg-gray-200 rounded-lg px-4 py-3 flex items-center justify-between">
                                <span className="text-base font-medium">Total Earnings</span>
                                <span className="text-lg font-bold">₹{data?.payment_config?.net_revenue.toFixed(2) || 0}</span>
                            </div>
                        </Col>
                        <Col xl={4} sm={6} className="mb-2">
                            <div className="bg-gray-200 rounded-lg px-4 py-3 flex items-center justify-between">
                                <span className="text-base font-medium">Total Amount To Distribute</span>
                                <span className="text-lg font-bold">₹{data?.payment_config?.amount_to_distribute.toFixed(2) || 0}</span>
                            </div>
                        </Col>
                    </Row>
                    <div className="dash-first dash-common">
                        <div className="common-table-wrapper m-0">
                            <Row className="mb-4">
                                <Col md={6}>
                                    <div className="flex gap-3">
                                        <label className="relative block">
                                            <span className="sr-only">Search</span>
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                                <svg className="h-5 w-5 fill-slate-500" viewBox="0 0 20 20">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                            </span>
                                            <input
                                                className="placeholder:text-gray-400 block bg-gray-200 w-full border border-slate-300 rounded-xl py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base"
                                                placeholder="Search"
                                                type="text"
                                                name="Search"
                                                value={searchQuery.search}
                                                onChange={handleSearch}
                                            />
                                        </label>
                                        <div className="flex items-center gap-3">
                                            <Dropdown>
                                                <Dropdown.Toggle className="bg-transparent p-0 border-0" id="dropdown-basic">
                                                    <span>
                                                        <img src={Sort} alt="sort" />
                                                    </span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="shadow border-0 rounded-xl">
                                                    <Dropdown.Item onClick={() => handleSort({ organizer_name: 1 })}>
                                                        Sort by Organizer Name (A-Z)
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleSort({ organizer_name: -1 })}>
                                                        Sort by Organizer Name (Z-A)
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleSort({ createdAt: 1 })}>
                                                        Sort by Date (Oldest first)
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleSort({ createdAt: -1 })}>
                                                        Sort by Date (Newest first)
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}></Col>
                            </Row>
                            <div className="table-responsive">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sr.No.</th>
                                            <th>Organizer Name</th>
                                            <th>Business Email</th>
                                            <th>Total Revenue Generated</th>
                                            <th>My Cut</th>
                                            <th>Tax</th>
                                            <th>Amount To Distribute</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.payment_list.length > 0 ? (
                                            data.payment_list.map(
                                                ({ _id, organizer_detail, total_revenue, my_cut, total_tax, amount_to_distribute }, i) => (
                                                    <tr>
                                                        <td>{(page - 1) * 10 + i + 1}</td>
                                                        <td>
                                                            <span className="flex gap-2 items-center">
                                                                <img
                                                                    src={organizer_detail?.image || Dummy}
                                                                    alt={_id}
                                                                    width="15"
                                                                    height="15"
                                                                    className="rounded-full"
                                                                />
                                                                {organizer_detail?.organizer_name}
                                                            </span>
                                                        </td>
                                                        <td>{organizer_detail?.business_email || "-"}</td>
                                                        <td>₹{total_revenue !== null ? total_revenue.toFixed(2) : 0}</td>
                                                        <td>₹{my_cut !== null ? my_cut.toFixed(2) : 0}</td>
                                                        <td>₹{total_tax !== null ? total_tax.toFixed(2) : 0}</td>
                                                        <td>₹{amount_to_distribute !== null ? amount_to_distribute.toFixed(2) : 0}</td>

                                                        <td className="action-tab flex justify-center gap-2">
                                                            <Link
                                                                className="inline-block"
                                                                to={`/payment-management/payment-detail/${organizer_detail._id}`}
                                                            >
                                                                <img src={View} alt="" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ),
                                            )
                                        ) : (
                                            <td className="text-center" colSpan={13}>
                                                No Records Founds
                                            </td>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {data?.payment_list.length > 0 ? (
                                <Row className="mt-4 px-2">
                                    <Col md={5}>
                                        <div className="dataTables_info">
                                            {`Showing ${Math.min((page - 1) * 10 + 1, data?.total)}` +
                                                ` to ${Math.min(page * 10, data?.total) || 0} of ${data?.total || 0} entries`}
                                        </div>
                                    </Col>
                                    <Col md={7}>
                                        <Pagination
                                            total={totalPages}
                                            setPage={(newPage) => {
                                                setSearchQuery((prevQuery) => ({
                                                    ...prevQuery,
                                                    page: newPage,
                                                }));
                                            }}
                                            page={page} // Add 1 to page state to show correct page number
                                            nextPage={nextPage} // Pass the next page number to the Pagination component
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </>
            )}
            <Modal dialogClassName="modal-custom-sm" centered show={delet} onHide={deleteClose}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3">
                            <p className="modalTitle text-center mb-2 fw-bold">
                                Are you sure you want to
                                <br />
                                delete this user?
                            </p>
                            <form className="custom_form">
                                <div className="btn-wrapper text-center">
                                    <Link className="btn btn-form btn-cancel" onClick={deleteClose}>
                                        No
                                    </Link>
                                    <Link className="btn btn-form btn-add" onClick={deleteClose}>
                                        Yes
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal dialogClassName="modal-custom-sm" centered show={block} onHide={blockClose}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3">
                            <p className="modalTitle text-center mb-2 fw-bold">
                                Are you sure you want to
                                <br />
                                block this user?
                            </p>
                            <form className="custom_form">
                                <div className="btn-wrapper text-center">
                                    <Link className="btn btn-form btn-cancel" onClick={blockClose}>
                                        No
                                    </Link>
                                    <Link className="btn btn-form btn-add" onClick={blockClose}>
                                        Yes
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PaymentManagement;
