import { configureStore } from "@reduxjs/toolkit";

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import { encryptTransform } from "redux-persist-transform-encrypt";

import { wtfApis } from "../src/Services/api";
import { toastReducer } from "./Services/tostify";

const reducers = combineReducers({
  [wtfApis.reducerPath]: wtfApis.reducer,
  toast: toastReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "logout/logout") {
    state = undefined;
  }
  return reducers(state, action);
};

const persistConfig = {
  key: "root",
  storage,
  transforms: [
    encryptTransform({
      secretKey: "my-super-secret-keyForWTF@otball@$S",
      onError: function (error) {
        // console.warn(error);
      },
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(wtfApis.middleware),
});

export default store;
