import React, { useEffect, useState } from "react";
import { MdMode } from "react-icons/md";
import { Link } from "react-router-dom";
import { useAddTermsNConditionMutation, useGetTermsNConditionQuery } from "../../../Services/api";
import { showToast } from "../../../Services/tostify";
import Spinnerr from "../../../Components/Spinner/Spinner";
import { useDispatch } from "react-redux";

const UserTnC = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);

    const [newData, setNewData] = useState({
        terms_and_condition: "",
        role_type: "user",
    });
    const [isEditing, setIsEditing] = useState(false);
    const body = {
        role_type: "user", // Role type only can be 'user' OR 'organizer'
    };
    const response = useGetTermsNConditionQuery(body, { refetchOnMountOrArgChange: true });
    const [addTermsNCondition, response2] = useAddTermsNConditionMutation();
    // console.log(response?.data);
    useEffect(() => {
        if (!response.isLoading) {
            setLoading(false);
            setData(response?.data?.data);
            // Initialize newData with the existing terms and conditions
            setNewData({ terms_and_condition: response?.data?.data?.terms_and_condition || "", role_type: "user" });
        } else if (response.isError) {
            setLoading(false);
            setError(true);
        }
    }, [response]);

    useEffect(() => {
        if (!response2.isLoading && response2.isSuccess) {
            setLoading(false);
            response.refetch();
            setIsEditing(false); // Hide the textarea after saving
            dispatch(showToast("Terms and Condition Updated", "SUCCESS_TOAST"));
        } else if (response2.isError || response2.status === "rejected") {
            setLoading(false);
            setError(true);
            dispatch(showToast(response2?.error?.data?.message, "FAIL_TOAST"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response2]);

    const handleSave = () => {
        addTermsNCondition(newData);
    };
    // console.log(newData);
    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "some error occured"
            ) : (
                <div className="dash-first dash-common box-border-3 mb-4">
                    <div className="card-body px-0">
                        {isEditing ? (
                            <>
                                <div className="card-head p-0 text-end gap-2 flex justify-end mb-4">
                                    <Link
                                        href="#"
                                        className="text-stone-600 hover:text-stone-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                        onClick={() => setIsEditing(false)} // Cancel editing
                                    >
                                        Cancel
                                    </Link>
                                    <Link
                                        href="#"
                                        className="text-white bg-red-600 hover:bg-red-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                        onClick={handleSave} // Save changes
                                    >
                                        Save
                                    </Link>
                                </div>
                                <textarea
                                    className="text-white w-100 textarea-round p-3"
                                    rows="12"
                                    value={newData.terms_and_condition}
                                    onChange={(e) => setNewData({ ...newData, terms_and_condition: e.target.value })}
                                />
                            </>
                        ) : (
                            <>
                                <div className="card-head p-0 text-end">
                                    <Link href="#" className="edit-icon" onClick={() => setIsEditing(true)}>
                                        <MdMode />
                                    </Link>
                                </div>
                                <p>{data?.terms_and_condition}</p>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default UserTnC;
